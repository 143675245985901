<template>
  <div :style="getStyleForSectionBlocks">
    <SectionBlockPanel
        :AppThemeSetting="AppThemeSetting"
        :sectionTitle="sectionTitle"
        :isHide="isHide"
        @clickHide="doIsHide"
        @clickShow="doIsShow"
    />
    <div>
      <div :style="getStyleAboveDescription">
        <div :style="getStyleDescription" v-show="!isHide">
          <img :src="getImgUrl"
               v-if="sectionImage!==undefined && sectionImage !==''"
               alt="page figure"
               :height="getImageHeight"
          >
          <video :height="getVideoHeight"
                 poster="../../../public/NFfavicon.png"
                 v-if="getVideoUrl !== undefined"
                 controls>
            <source :src="getVideoUrl" type="video/mp4">
            Your browser does not support the video tag
          </video>
          <div :style="getStyleDescriptionText"
               v-if="sectionTextArray && sectionTextArray.length > 0">
            <p v-for="(item, index) in sectionTextArray" :key="index">
              {{ item }}
            </p>
          </div>
          <slot name="sectionContent"></slot>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Vue from 'vue'

import config from '@/config/config-css.js'
import CheckMobileMixinMX from "@/mixins/CheckMobileMixin_MX";
import SectionBlockPanel from "@/components/collector-parts/SectionBlockPanel";

export default {
  data: () => {
    return {
      config,
      pageName: 'sectionB block',

      // bus
      bus: new Vue(),

      // stuff for debug
      tempData: {},
    }
  },

  mixins: [CheckMobileMixinMX],
  components: {
    SectionBlockPanel,
  },

  props: {
    AppThemeSetting: {
      type: String,
      required: false,
      default: "themeOne"
    },
    isHide: {
      type: Boolean,
      required: false,
      default: false
    },
    sectionTitle: {
      type: String,
      required: false,
      default: 'Section Title'
    },
    sectionTextArray: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    sectionImage: {
      type: String,
      required: false,
      default: undefined
    },
    sectionVideo: {
      type: String,
      required: false,
      default: undefined
    }
  },

  methods: {
    // loading data

    // Button action
    doIsHide() {
      this.$emit('clickHide')
    },
    doIsShow() {
      this.$emit('clickShow')
    },

    // utilities
  },

  computed: {
    // for debugging only
    deactivateMe() {
      return true
    },

    getImageHeight() {
      return this.windowWidth < 1.3 * this.configCssAll.descriptionAreaFigureHeightInPx ?
          (1/1.3) * this.windowWidth + 'px': this.configCssAll.descriptionAreaFigureHeightInPx + 'px';
    },
    getVideoHeight() {
      return this.windowWidth < 1.3 * this.configCssAll.descriptionAreaVideoHeightInPx ? (1/1.3) * this.windowWidth + 'px': this.configCssAll.descriptionAreaVideoHeightInPx + 'px' + 'px';
    },
    getImgUrl() {
      if (this.sectionImage === undefined || this.sectionImage === '') {
        return undefined
      } else {
        return 'help-pages-images/' + this.sectionImage;
      }
    },
    getVideoUrl() {
      if (this.sectionVideo === undefined || this.sectionVideo === '') {
        return undefined
      } else {
        return 'help-pages-movies/' + this.sectionVideo;
      }
    },

    // styles
    getStyleForSectionBlocks() {
      let theStyle = {
        'background-color': this.configCssAll.sectionBlocksBackgroundColor,
        'color': this.configCssAll.sectionBlocksFontColor,
        padding: this.configCssAll.overallContainerPadding,
        margin: this.configCssAll.sectionBlocksMargins,
      }
      theStyle.width = 'auto';
      return theStyle
    },

    getStyleAboveDescription() {
      return {
        'display': 'block',
        padding: '0 0 0 ' + this.configCssSBP.spacingBeforeTitle,
        'color': this.configCssAll.descriptionAreaFontColor,
        'background': this.configCssAll.descriptionAreaBackgroundColor,
        width: '98%',
      }
    },
    getStyleDescription() {
      return {
        'display': 'block',
        padding: this.configCssAll.descriptionPadding,
        'color': this.configCssAll.descriptionAreaFontColor,
        'background': this.configCssAll.descriptionAreaBackgroundColor,
      }
    },
    getStyleDescriptionText() {
      return {
        'display': 'block',
        padding: this.configCssAll.descriptionTextPadding,
        'color': this.configCssAll.descriptionAreaFontColor,
        'background': this.configCssAll.descriptionAreaBackgroundColor,
        'width': this.windowWidth < this.configCssAll.descriptionTextWidthInPx ? '100%' :
            '' + this.configCssAll.descriptionTextWidthInPx + 'px'
      }
    },

        configCssSBP() {
      return this.config[this.AppThemeSetting].SectionBlockPanel
    },

    configCssAll() {
      return this.config[this.AppThemeSetting].appAllUnits
    },
    configCssAllTSL() {
      return this.config[this.AppThemeSetting].appAllUnits.appToolsSelectionList
    },
  },

  created() {
    this.$nextTick(async function () {
        }
    )
  }
}
</script>
