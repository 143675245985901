<template>
  <div :style="{height:'100%', position:'absolute'}" class="homeTop">
    <div :style="getStyleMetaOverallContainerMainRegion" class="homeLevel1">
      <TheAppBaseView
          :AppThemeSetting="AppThemeSetting"
          :useLocalStorageDropdownLinks="true"
      >

        <template v-slot:mainRegionContent>
          <HelpPageTitle
              :AppThemeSetting="AppThemeSetting"
              :titleText="configCss.mainTitleText"
          />
          <div v-if="isHideMap !== undefined">
            <div v-for="(item, index) in configCss.parts"
                 :key="index"
            >
              <SectionBlock
                  :id="getIdLabel(index)"
                  :AppThemeSetting="AppThemeSetting"

                  :sectionTitle="item.sectionTitle"
                  :sectionTextArray="item.sectionTextArray"
                  :sectionImage="item.sectionImage ? item.sectionImage : undefined"
                  :sectionVideo="item.sectionVideo ? item.sectionVideo : undefined"

                  :isHide="isHideMap['' + index]"
                  @clickHide="isHideMap['' + index]=true"
                  @clickShow="() => doShow(index)"
              />
              <SpacingBetween/>
            </div>
            <div :style="getStyleAtEnd"></div>
          </div>
        </template>
      </TheAppBaseView>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import TheAppBaseView from "@/components/collector-components/TheAppBaseView";
import SectionBlock from "@/components/collector-parts/SectionBlock";
import HelpPageTitle from "@/components/collector-parts/HelpPageTitle";

import config from '@/config/config-css.js'
import CheckMobileMixinMX from "@/mixins/CheckMobileMixin_MX";
import SpacingBetween from "@/components/collector-parts/SpacingBetween";

export default {
  data: () => {
    return {
      config,
      pageName: 'Home',

      // show flags
      isHideMap: {},

      // main data

      // selection groups

      // bus
      bus: new Vue(),

      // stuff for debug
      tempData: {},
    }
  },

  mixins: [CheckMobileMixinMX],
  components: {
    SpacingBetween,
    TheAppBaseView, SectionBlock, HelpPageTitle,
  },

  props: {
    AppThemeSetting: {
      type: String,
      required: false,
      default: "themeOne"
    },
    nameOfPage: {
      type: String,
      required: true
    }
  },

  methods: {
    initialHideMap() {
      let val;
      for (let i = 0; i < this.configCss.parts.length; i++) {
        val = this.configCss.parts[i]['startHidden'] ? this.configCss.parts[i]['startHidden'] : false;
        Vue.set(this.isHideMap, '' + i, val)
      }
    },
    nav(id) {
      const el = document.getElementById(id);
      if (!el) {
        return;
      }
      const position = el.offsetTop - 120;
      // smooth scroll
      window.scrollTo({top: position, behavior: "smooth"});
      // el.scrollIntoView()
    },
    doShow(index) {
      this.isHideMap['' + index] = false;
      this.$nextTick(async function () {
        this.nav(this.getIdLabel(index))
      })
    },
    getIdLabel(index) {
      return 'section ' + index
    }
  },

  computed: {
    // styles
    getStyleMetaOverallContainerMainRegion() {
      let theStyle = {
        'background-color': this.configCssAll.overallBackgroundColor,
        height: "100%",
        'min-height': "100%",
        display: 'flex',
        'flex-direction': 'column',
        'align-items': 'flex-start',
      }
      return theStyle
    },
    getStyleForSectionBlocks() {
      let theStyle = {
        'background-color': this.configCssAll.sectionBlocksBackgroundColor,
        'color': this.configCssAll.sectionBlocksFontColor,
        padding: this.configCssAll.overallContainerPadding,
        margin: this.configCssAll.sectionBlocksMargins,
      }
      theStyle.width = 'auto';
      return theStyle
    },
    getStyleForAppIdentificationBlock() {
      return {
        display: 'flex',
        'flex-direction': 'row',
      }
    },
    getStyleForAppIdentificationMember() {
      return {
        'display': 'inline-block',
        'color': this.configCssAll.sectionBlocksFontColor,
        'font-size': '12px',
        'width': 'auto',
        'padding': '0 32px 0 32px',
      }
    },

    getStyleAppToolsContainer() {
      const theStyle = {
        padding: '0 0 0 0',
        'height': this.configCssAllTSL.height,
      }
      theStyle['width'] = this.isMobile ? '100%' : '100%';
      return theStyle
    },
    getStyleTitleBlock() {
      return {
        display: 'block',
        width: '98%',
        height: '100%',
        padding: '4px 0 0 ' + this.configCssAll.spacingBeforeBlockTitle,
        'color': this.configCssAllTSL.titleFontColor,
        'background': this.configCssAllTSL.titleBackgroundColor,
      }
    },
    getTitleStyle() {
      return {
        'color': this.configCssAllTSL.titleFontColor,
        'font-size': this.configCssAllTSL.titleFontSize,
        'font-family': this.configCssAllTSL.titleFontFamily,
        'background': this.configCssAllTSL.titleBackgroundColor,
        'padding': !this.isMobile ? this.configCssAllTSL.titlePadding : this.configCssAllTSL.titleMobilePadding,
        'font-weight': !this.isMobile ? this.configCssAllTSL.titleFontWeight : this.configCssAllTSL.titleMobileFontWeight,
        margin: 0,
      };
    },
    getStyleHideButtonContainer() {
      return {
        'display': 'inline-block',
        padding: '0 0 0 16px',
      }
    },
    getStyleAboveDescription() {
      return {
        'display': 'block',
        padding: '0 0 0 ' + this.configCssAll.spacingBeforeBlockTitle,
        'color': this.configCssAll.descriptionAreaFontColor,
        'background': this.configCssAll.descriptionAreaBackgroundColor,
        width: '98%',
      }
    },
    getStyleDescription() {
      return {
        'display': 'block',
        padding: this.configCssAll.descriptionPadding,
        'color': this.configCssAll.descriptionAreaFontColor,
        'background': this.configCssAll.descriptionAreaBackgroundColor,
      }
    },
    getStyleDescriptionText() {
      return {
        'display': 'block',
        padding: this.configCssAll.descriptionTextPadding,
        'color': this.configCssAll.descriptionAreaFontColor,
        'background': this.configCssAll.descriptionAreaBackgroundColor,
        'width': this.windowWidth < 400 ? '100%' : '400px'
      }
    },
    getStyleAtEnd() {
      return {
        height: this.configCssAll.spaceAtEnd
      }
    },

    configCss() {
      return this.config[this.AppThemeSetting][this.nameOfPage]
    },
    configCssAll() {
      return this.config[this.AppThemeSetting].appAllUnits
    },
    configCssAllTSL() {
      return this.config[this.AppThemeSetting].appAllUnits.appToolsSelectionList
    },
  },

  mounted() {
    this.$nextTick(async function () {
      this.initialHideMap()
    })
  }
}
</script>
