var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.hideTheContent),expression:"hideTheContent"}],staticClass:"AppDropdownContainingDiv--scoped"},[_c('AppButton',{attrs:{"clickFunction":_vm.toggleTheContent,"buttonText":_vm.dropdownButtonText,"buttonTextFontColor":_vm.dropdownButtonTextColor,"buttonTextFontSize":_vm.dropdownButtonTextFontSize,"buttonTextFontFamily":_vm.dropdownButtonTextFontFamily,"buttonBackgroundColor":_vm.dropdownButtonBackground,"buttonHoverBackgroundColor":_vm.dropdownHoverButtonBackground,"buttonPadding":_vm.dropdownButtonPadding,"buttonBorder":_vm.dropdownButtonBorder,"buttonBorderRadius":_vm.dropdownButtonBorderRadius,"buttonBoxShadow":_vm.dropdownButtonBoxShadow,"buttonIconName":_vm.dropdownIconName,"buttonIconHeight":_vm.dropdownIconHeight,"buttonIconPadding":_vm.dropdownIconPadding}}),_c('div',{class:['dropdownContent--scoped',
                { 'show':_vm.showContent}],style:(_vm.calculatedDropdownContentContainerStyle())},_vm._l((_vm.dropdownItems),function(ref){
                var clickFunction = ref.clickFunction;
                var key = ref.key;
                var data = ref.data;
                var label = ref.label;
                var type = ref.type;
                var addMarkAfter = ref.addMarkAfter;
                var checked = ref.checked;
return _c('div',{key:key,style:(_vm.calculateDropdownContentStyle(key, type)),on:{"mouseover":function($event){return _vm.doDropdownHover(key)},"mouseleave":function($event){return _vm.doDropdownLeave(key)},"click":function($event){type === 'checkbox'? _vm.doNothing : _vm.doClickNotCheckBox(clickFunction, key)},"change":function($event){type === 'checkbox'? clickFunction() : _vm.doNothing}}},[(type==='checkbox')?_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":checked}}):_vm._e(),_c('span',{attrs:{"value":data},on:{"click":function($event){$event.preventDefault();return (function () { return _vm.$emit('clickedCheckbox', data); }).apply(null, arguments)}}}),_vm._v(" "+_vm._s(label)+" "),(addMarkAfter && addMarkAfter !== '')?_c('span',[_vm._v(_vm._s(addMarkAfter))]):_vm._e()])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }