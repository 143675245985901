<template>
  <div :style="getStyleAppToolsContainer" class="classStickyColumn">
    <div :style="getStyleTitleBlock">
      <label :style="getTitleStyle">
        {{ sectionTitle }}
      </label>
      <div :style="getStyleHideButtonContainer">
        <AppButtonHide
            :AppThemeSetting="AppThemeSetting"
            :isHide="isHide"
            @clickHide="doIsHide"
            @clickShow="doIsShow"
        />
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config/config-css.js'
import CheckMobileMixinMX from "@/mixins/CheckMobileMixin_MX";
import AppButtonHide from "@/components/Buttons/AppButtonHide";

export default {
  data: () => {
    return {
      config
    }
  },
  mixins: [CheckMobileMixinMX],

  components: {AppButtonHide},

  props: {
    AppThemeSetting: {
      type: String,
      required: false,
      default: "themeOne"
    },
    sectionTitle: {
      type: String,
      required: false,
      default: 'Section Title'
    },
    isHide: {
      type: Boolean,
      required: false,
      default: false
    },
  },

  methods: {
    doIsHide() {
      this.$emit('clickHide')
    },
    doIsShow() {
      this.$emit('clickShow')
    },
  },

  computed: {
    getStyleAppToolsContainer() {
      const theStyle = {
        padding: '0 0 0 0',
        'height': this.configCss.height,
      }
      theStyle['width'] = this.isMobile ? '100%' : '100%';
      return theStyle
    },
    getStyleTitleBlock() {
      return {
        display: 'block',
        width: '98%',
        height: '100%',
        padding: '4px 0 0 ' + this.configCss.spacingBeforeTitle,
        'color': this.configCss.titleFontColor,
        'background': this.configCss.titleBackgroundColor,
      }
    },
    getTitleStyle() {
      return {
        'color': this.configCss.titleFontColor,
        'font-size': this.configCss.titleFontSize,
        'font-family': this.configCss.titleFontFamily,
        'background': this.configCss.titleBackgroundColor,
        'padding': !this.isMobile ? this.configCss.titlePadding : this.configCss.titleMobilePadding,
        'font-weight': !this.isMobile ? this.configCss.titleFontWeight : this.configCss.titleMobileFontWeight,
        margin: 0,
      };
    },
    getStyleHideButtonContainer() {
      return {
        'display': 'inline-block',
        padding: '0 0 0 16px',
      }
    },

    configCss() {
      return this.config[this.AppThemeSetting].SectionBlockPanel
    },

  }
}
</script>