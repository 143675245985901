const checkMobileMixin = {
    data: () => {
        return {
            isMobile: false,
            isMobilePlus: false,
            isNarrowWindow: false,
            windowWidth: undefined,
        }
    },
    methods: {
        onResize() {
            this.isMobile = window.innerWidth <= 567;
            this.isMobilePlus = window.innerWidth <= 635;
            this.isNarrowWindow = window.innerWidth <= 750;
            this.windowWidth = document.documentElement.clientWidth;
        }
    },

    mounted() {
        this.$nextTick(function() {window.addEventListener('resize', this.onResize)});
        this.onResize();
    },
    created() {
        window.addEventListener('resize', this.onResize)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    }

}

export default checkMobileMixin