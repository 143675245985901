<template>
  <div @keydown.esc="$emit('clickCancel')">
    <AppModal
        :showModal="showModal"
        :position='configCssOk.position'
        :top="top !== undefined && top !== '' ? top : configCssOk.top"
        :left="left !== undefined && left !== '' ? left : configCssOk.left"
        :containerPadding="configCss.containerPadding"
        :containerWidth="configCss.containerWidth"
        :borderRadius="configCss.borderRadius"
        :backgroundColor=configCss.backgroundColor
        :boxShadow="configCss.boxShadow"
        :fontFamily="configCss.fontFamily"
        :headFontSize="configCss.headFontSize"
        :headFontColor="configCss.headFontColor"
        :bodyFontSize="configCss.bodyFontSize"
        :bodyFontColor="configCss.bodyFontColor"
        :footerFontSize="configCss.footerFontSize"
        :footerFontColor="configCss.footerFontColor"
    >
      <template v-slot:header>
        <div :style="{'text-align': 'center', 'font-weight': configCss.headFontWeight,
      'background-color':configCss.headBackgroundColor}">
          {{ titleText }}
        </div>
      </template>
      <template v-slot:body>
        <div :style="{'padding': '8px 0 0 0'}">
          {{ bodyText }}
        </div>
      </template>
      <template v-slot:footer>
        <div :style="{display:'inline-flex', 'flex-direction': 'row',
          'align-items':'text-top', 'justify-content':'space-around',
          'width':'100%', 'padding': '16px 0 8px 0'}">
          <span @mousedown="OKShadow=false"
                @mouseleave="OKShadow=true"
                @mouseup="OKShadow=true"
          >
          <AppButton
              :clickFunction="() => $emit('clickOK')"
              :buttonText="buttonText"
              :buttonTextFontColor="configCssOk.buttonOKTextFontColor"
              :buttonBackgroundColor="configCssOk.buttonOKBackgroundColor"
              :buttonHoverBackgroundColor="configCssOk.buttonOKHoverBackgroundColor"
              :buttonTextFontSize="configCss.buttonTextFontSize"
              :buttonTextFontFamily="configCss.buttonTextFontFamily"
              :buttonPadding="buttonPadding ? buttonPadding : configCss.buttonPadding"
              :buttonBorder="configCss.buttonBorder"
              :buttonBorderRadius="configCss.buttonBorderRadius"
              :buttonBoxShadow="OKShadow ? configCss.buttonBoxShadow : 'none'"
          />
        </span>
        </div>
      </template>

    </AppModal>
  </div>
</template>

<script>
import AppModal from "@/components/Modals/AppModal";
import config from "@/config/config-css";
import AppButton from "@/components/Buttons/AppButton";

// Emits: 'clickOK'
export default {
  data: () => {
    return {
      config,
      OKShadow: true,
    }
  },

  components: {
    AppModal, AppButton,
  },

  props: {
    AppThemeSetting: {
      type: String,
      required: false,
      default: "themeOne"
    },
    top: {
      type: String,
      required: false,
      default: ''
    },
    left: {
      type: String,
      required: false,
      default: ''
    },
    showModal: {
      type: Boolean,
      required: false,
      default: false
    },
    titleText: {
      type: String,
      required: false,
      default: "Warning"
    },
    bodyText: {
      type: String,
      required: false,
      default: "Warning"
    },
    buttonText: {
      type: String,
      required: false,
      default: "ok"
    },
    buttonPadding: {
      type: String,
      required: false,
      default: "none"
    },

  },

  methods: {
    actOnEscape(e) {
      if (e.key === 'Escape' && this.showModal) {
        this.$emit('clickOK');
      }
    }
  },

  computed: {
    configCss() {
      return this.config[this.AppThemeSetting].appAllUnits.appModal
    },
    configCssOk() {
      return this.config[this.AppThemeSetting].appAllUnits.appModal.ok
    }
  },

  created() {
    window.addEventListener('keydown', this.actOnEscape);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.actOnEscape)
  },

}
</script>