<template>
  <TheAppBaseView>
    <template v-slot:mainRegionContent>
      <HelpPageTitle
          :AppThemeSetting="AppThemeSetting"
          :titleText="configCss.mainTitle"
      />
      <div :style="getStyleSurroundingContactUs">
        <ContactUsContent
            :AppThemeSetting="AppThemeSetting"
            @contactUs="doContactUs"
        />
      </div>
      <AppModalOK
          :AppThemeSetting="AppThemeSetting"
          :showModal="showContactUsFailError"
          :top="getModalOKTop"
          :left="getModalOKLeft"
          :titleText="'Contact Us Failed'"
          :bodyText="'Connection error. Please check connection or try later'"
          :buttonText="'ok'"
          :buttonPadding="'2px 16px 2px 16px'"
          @clickOK="showContactUsFailError=false"
      />
      <AppModalOK
          :AppThemeSetting="AppThemeSetting"
          :showModal="showContactUsSuccess"
          :top="getModalOKTop"
          :left="getModalOKLeft"
          :titleText="'Thank you for contacting us'"
          :bodyText="'We will respond to your message as soon as possible'"
          :buttonText="'ok'"
          :buttonPadding="'2px 16px 2px 16px'"
          @clickOK="showContactUsSuccess=false"
      />
    </template>
  </TheAppBaseView>
</template>

<script>
// import Vue from 'vue'
import TheAppBaseView from "@/components/collector-components/TheAppBaseView";
import HelpPageTitle from "@/components/collector-parts/HelpPageTitle";
import ContactUsContent from "@/components/collector-parts/ContactUsContent";

import config from '@/config/config-css.js'
import CheckMobileMixinMX from "@/mixins/CheckMobileMixin_MX";

import mgoAPI from "../api/mgo-core-api";
import AppModalOK from "@/components/Modals/AppModalOK";

const contactUsEXT = mgoAPI.contactUsEXT;

export default {
  data: () => {
    return {
      config,

      isHideMap: {
        mainContents: false,
      },
      showContactUsFailError: false,
      showContactUsSuccess: false,

      // show flags
    }
  },

  mixins: [CheckMobileMixinMX],
  components: {
    AppModalOK,
    TheAppBaseView, HelpPageTitle, ContactUsContent,
  },

  props: {
    AppThemeSetting: {
      type: String,
      required: false,
      default: "themeOne"
    },
  },

  methods: {
    async doContactUs({email, message}) {
      if (email === undefined || message === undefined || email === '' || message === '') {
        return;
      }
      let result
      try {
        result = await contactUsEXT({email, message, isLoggedIn: false})
      } catch (e) {
        this.showContactUsFailError = true;
        return;
      }
      if (result === undefined || result.success === false) {
        this.showContactUsFailError = true;
      } else {
        this.showContactUsSuccess = true;
      }
    },
  },

  computed: {
    getModalOKTop() {
      return this.isNarrowWindow ? this.configCssOK.modalOKNarrowTop : this.configCssOK.modalOKTop;
    },
    getModalOKLeft() {
      return this.isNarrowWindow ? this.configCssOK.modalOKNarrowLeft : this.configCssOK.modalOKLeft;
    },

    getStyleSurroundingContactUs() {
      return {
        padding: this.configCss.paddingAroundContactUs
      }
    },
    configCss() {
      return this.config[this.AppThemeSetting].ContactUs
    },
    configCssOK() {
      return this.config[this.AppThemeSetting].appAllUnits
    },
  },

  created() {
    this.$nextTick(async function () {
    })
  }
}
</script>
