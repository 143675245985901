import axios from 'axios'
import config from '../config/config-general'

const environment = process.env.VUE_APP_ENVIRONMENT || "testing";

const apiUrl = config[environment].identifyUrl;

export default {
    mgoAPIAxiosRequestPreLogin
};

async function mgoAPIAxiosRequestPreLogin({
                                              path = '/', method = 'get',
                                              withCredentials = true, body = undefined
                                          } =
                                              {path: '/', method: 'get', withCredentials: true, body: {}}) {
    const headers = {};

    const requestObject = {
        url: `${apiUrl}${path}`,
        method,
        responseType: 'json',
        headers,
        // data: {},
        withCredentials
    };
    if (method === 'post' || method === 'delete') {
        requestObject.data = body;
    }

    let result, status;
    try {
        result = await axios(requestObject);
        status = result.status;
    } catch (error) {
        result = result ? result : {};
        status = error.response.status;
        if (error.response) {
            result.status = error.response.status;
            return {...result, status}
        } else if (error.request) {
            result.connectionError = true;
            return {...result, status}
        } else {
            result.possibleBug = true;
            result.bugSource = 'frontend'
            return {...result, status};
        }
    }
    if (!result || !Object.keys(result.data).includes('success')) {
        result.possibleBug = true;
        result.bugSource = 'backend'
        result.success = false;
        return {success: false, ...result, status};
    }
    return {...result.data, status};
}
