import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Courses from "@/views/Courses";
import Course from "@/views/Course";
import Assessment from "@/views/Assessment";
import FinalGrades from "@/views/FinalGrades";
import Offering from "@/views/Offering";
import Students from "@/views/Students";
import ContactUs from "@/views/ContactUs";
import Archiving from "@/views/Archiving";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/Courses',
    name: 'Courses',
    component: Courses
  },
  {
    path: '/Course',
    name: 'Course',
    component: Course
  },
  {
    path: '/Assessment',
    name: 'Assessment',
    component: Assessment
  },
  {
    path: '/Offering',
    name: 'Offering',
    component: Offering
  },
  {
    path: '/FinalGrades',
    name: 'FinalGrades',
    component: FinalGrades
  },
  {
    path: '/Students',
    name: 'Students',
    component: Students
  },
  {
    path: '/offeringSnapshots',
    name: 'Archiving',
    component: Archiving
  },
  {
    path: '/*',
    name: 'default',
    component: Home
  }
]

const router = new VueRouter({
  routes
})

// router.beforeEach(async (to, from, next) => {
// })

export default router
