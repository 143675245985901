<template>
  <div :style="getStyleTop()">
    <div :style="getStyleBelowTop">
      <div :style="getStyleBody">
        <div :style="getStyleTitle">Contact us</div>
        <div :style="getStyleSubtitle">Please enter your email and a brief message</div>
        <div :style="getStyleInputContainer">
          <input type="email" :style="getStyleEmailInput" v-model="email" placeholder="Email">
        </div>
        <div :style="getStyleInputContainer">
          <textarea :style="getStyleMessageInput"
                    v-model="message"
                    placeholder="Message"
          />
        </div>
        <div :style="getStyleSubmitContainer">
          <button :style="getStyleSubmitButton"
                  @click="doClickSubmit"
                  @mouseover="doSubmitHover()"
                  @mouseleave="doSubmitLeave()"
                  @mousedown="shadowButton=false"
                  @mouseup="shadowButton=true"
          >Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config/config-css";
import checkMobileMixin from '@/mixins/CheckMobileMixin_MX.js';


export default {
  data() {
    return {
      config,
      isSubmitHover: false,
      shadowButton: true,
      email: '',
      message: '',
    }
  },

  mixins: [checkMobileMixin],

  props: {
    AppThemeSetting: {
      type: String,
      required: false,
      default: "themeOne"
    },
    isBlink: {
      type: Boolean,
      required: false,
      default: false
    },
    isBlinkDuration: {
      type: Number,
      required: false,
      default: undefined
    },
  },

  methods: {
    doClickSubmit() {
      if (this.email.trim() === '' || this.message.trim() === '') {
        return;
      }
      this.$emit('contactUs', {email: this.email, message:
        this.configCss.preMessageText + "\n" +
        this.message})
      this.email = '';
      this.message = '';
    },
    doSubmitHover() {
      this.isSubmitHover = true;
    },
    doSubmitLeave() {
      this.isSubmitHover = false;
    },
    getStyleTop() {
      const theStyle = {
        'width': this.windowWidth < this.configCss.overallWidthInPx + 40 ? '90%' :
            '' + this.configCss.overallWidthInPx + 'px',
        'background-color': this.configCss.overallBackground,
        'padding': this.configCss.overallPadding,
        'border': this.configCss.overallBorder,
        'border-radius': this.configCss.overallBorderRadius
      }
      const theThis = this;
      if (this.isBlink) {
        theStyle['background-color'] = this.configCss.overallBackgroundBlink;
        setTimeout(() => {
          theThis.$emit('blinkEnd')
        }, this.isBlinkDuration);
      }
      return theStyle;
    },
    doMessageInput(value) {
      this.message = value;
    },
  },

  computed: {
    getStyleBelowTop() {
      return {}
    },
    getStyleTitle() {
      return {
        'font-size': this.configCss.titleFontSize,
        'color': this.configCss.titleFontColor,
        'padding': this.configCss.titlePadding,
        'text-align': 'center',
      }
    },
    getStyleSubtitle() {
      return {
        'font-size': this.configCss.subtitleFontSize,
        'color': this.configCss.subtitleFontColor,
        'padding': this.configCss.subtitlePadding,
      }
    },
    getStyleBody() {
      return {
        'text-align': 'left',
      }
    },
    getStyleInputContainer() {
      return {
        'width': '100%',
        'padding': this.configCss.inputPadding,
      }
    },
    getStyleEmailInput() {
      return {
        'width': '100%',
        'border': this.configCss.borderInput,
        'border-radius': this.configCss.borderInputRadius,
      }
    },
    getStyleMessageInput() {
      return {
        'width': '100%',
        'height': this.configCss.noteHeight,
        'min-height': this.configCss.noteMinHeight,
        'resize':'vertical',
        'border': this.configCss.borderInput,
        'border-radius': this.configCss.borderInputRadius,
        'font-size': this.configCss.noteFontSize,
        'font-family': this.configCss.noteFontFamily,
        'color': this.configCss.noteFontColor,
      }
    },
    getStyleSubmitContainer() {
      return {
        'width': '100%',
        'padding': this.configCss.submitPadding
      }
    },
    getStyleSubmitButton() {
      const theStyle = {
        'width': '100%',
        'border': this.configCss.borderSubmit,
        'border-radius': this.configCss.borderSubmitRadius,
        'color': this.configCss.submitFontColor,
        'background-color': this.configCss.submitBackgroundColor,
        'box-shadow': this.configCss.submitShadow,
        'padding': '4px 0 4px 0',
      }
      if (this.isSubmitHover) {
        theStyle['background-color'] = this.configCss.submitHoverBackgroundColor;
      }
      if (!this.shadowButton) {
        theStyle['box-shadow'] = 'none';
      }
      return theStyle;
    },

    configCss() {
      return config[this.AppThemeSetting].ContactUs
    },

  }
}
</script>