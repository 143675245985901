<template>
  <div>
    <div :style="getStyleContentSection">
      <span @click="goToPage(routerPageName)" :style="getStyleLink">{{ linkLabel }}</span>
      <span :style="getStyleContent">: {{ linkDescription }}</span>
      <div :style="getStyleLineSeparator" v-if="configCss.showHorizontalSeparator"></div>
    </div>
  </div>
</template>

<script>
import config from '@/config/config-css.js'
import router from "@/router";

export default {
  data: () => {
    return {
      config
    }
  },

  props: {
    AppThemeSetting: {
      type: String,
      required: false,
      default: "themeOne"
    },
    routerPageName: {
      type: String,
      required: true
    },
    linkLabel: {
      type: String,
      required: false,
      default: 'Link text: '
    },
    linkDescription: {
      type: String,
      required: false,
      default: 'Link description'
    }
  },

  methods: {
    goToPage(name) {
      if (router.currentRoute.name === name) {
        window.scrollTo(0, 0)
      } else {
        router.push({
          name: name,
          params: {
            AppThemeSetting: this.AppThemeSetting
          }
        });
        window.scrollTo(0, 0)
      }
    }
  },

  computed: {
    getStyleContentSection() {
      return {
        display: 'block',
        padding: this.configCss.paddingContent,
        background: this.configCss.backgroundColor
      }
    },
    getStyleLink() {
      return {
        cursor: 'pointer',
        color: this.configCss.linkFontColor,
        'text-decoration': this.configCss.linkTextDecoration,
      }
    },
    getStyleContent() {
      return {
        color: this.configCss.contentColor,
      }
    },
    getStyleLineSeparator() {
      return {
        width: this.configCss.horizontalSeparatorWidth,
        height: this.configCss.horizontalSeparatorHeight,
        'border-bottom-width': this.configCss.horizontalSeparatorThickness,
        'border-bottom-style': 'solid',
        'border-bottom-color': this.configCss.horizontalSeparatorColor,
      }
    },

    configCss() {
      return this.config[this.AppThemeSetting].ContentItem
    },
    configCssAll() {
      return this.config[this.AppThemeSetting].appAllUnits
    },

  }
}
</script>