<template>
  <span @mousedown="shadowButton=false"
        @mouseover="doButtonHover()"
        @mouseleave="doButtonLeave()"
        @mouseup="shadowButton=true"
  >
    <button
        :class="['button--scoped']"
        :style="calculatedButtonContainerStyle"
        @click="clickFunction"
        :disabled="!buttonEnabled"
    ><span>{{ buttonText }}
      <div :style="calculatedIconStyle">
        <img v-if="buttonIconName && buttonIconName !== ''"
             :src="getImgUrl(buttonIconName)"
             :height="buttonIconHeight"
        >
      </div></span>
    </button>
  </span>
</template>

<script>
const defaultButtonBackgroundColor = "#ddd";
const defaultButtonHoverBackgroundColor = "#ddd";
const defaultButtonColor = "black";
const defaultButtonFontSize = "1rem";
const defaultButtonFontFamily = "sans-serif";
const defaultButtonPadding = "5px 5px 5px 5px";
const defaultIconPadding = "none";
const defaultButtonBorder = "none";
const defaultButtonBorderRadius = "none";
const defaultButtonBoxShadow = "none";
const defaultDisabledButtonBoxShadow = "none";
const defaultDisabledButtonColor = "gray";

export default {
  data: () => {
    return {
      isHovering: false,
      shadowButton: true,
    }
  },
  props: {
    clickFunction: {
      type: Function,
      required: false,
      default: () => {
      }
    },
    buttonEnabled: {
      type: Boolean,
      required: false,
      default: true
    },
    buttonText: {
      type: String,
      required: false,
      default: 'button'
    },
    buttonTextFontColor: {
      type: String,
      required: false,
      default: defaultButtonColor
    },
    buttonDisabledTextFontColor: {
      type: String,
      required: false,
      default: defaultDisabledButtonColor
    },
    buttonDisabledBoxShadow: {
      type: String,
      required: false,
      default: defaultDisabledButtonBoxShadow
    },
    buttonTextFontSize: {
      type: String,
      required: false,
      default: defaultButtonFontSize
    },
    buttonTextFontFamily: {
      type: String,
      required: false,
      default: defaultButtonFontFamily
    },
    buttonBackgroundColor: {
      type: String,
      required: false,
      default: defaultButtonBackgroundColor
    },
    buttonHoverBackgroundColor: {
      type: String,
      required: false,
      default: defaultButtonHoverBackgroundColor
    },
    buttonPadding: {
      type: String,
      required: false,
      default: defaultButtonPadding
    },
    buttonBorder: {
      type: String,
      required: false,
      default: defaultButtonBorder
    },
    buttonBorderRadius: {
      type: String,
      required: false,
      default: defaultButtonBorderRadius
    },
    buttonBoxShadow: {
      type: String,
      required: false,
      default: defaultButtonBoxShadow
    },
    buttonIconName: {
      type: String,
      required: false,
      default: ""
    },
    buttonIconHeight: {
      type: Number,
      required: false,
      default: 12
    },
    buttonIconPadding: {
      type: String,
      required: false,
      default: defaultIconPadding
    },
  },
  methods: {
    getImgUrl(pic) {
      if (!pic || pic === '') {
        return false
      } else {
        return require("@/assets/imgs/" + pic)
      }
    },
    doButtonHover() {
      this.isHovering = true;
    },
    doButtonLeave() {
      this.isHovering = false;
    },
  },

  computed: {
    calculatedIconStyle() {
      const theStyle = {
        display: "inline-block",
        padding: this.buttonIconPadding && this.buttonIconPadding !== '' ? this.buttonIconPadding : defaultIconPadding,
        'background-color': this.buttonBackgroundColor && this.buttonBackgroundColor !== '' ? this.buttonBackgroundColor : defaultButtonBackgroundColor
      };
      if (this.isHovering) {
        theStyle['background-color'] = this.buttonHoverBackgroundColor && this.buttonHoverBackgroundColor !== '' ? this.buttonHoverBackgroundColor : defaultButtonHoverBackgroundColor;
        theStyle.cursor = 'pointer';
      }
      return theStyle
    },
    calculatedButtonContainerStyle: function () {
      const theStyle = {
        color: this.buttonTextFontColor && this.buttonTextFontColor !== '' ? this.buttonTextFontColor : defaultButtonColor,
        'font-size': this.buttonTextFontSize && this.buttonTextFontSize !== '' ? this.buttonTextFontSize : defaultButtonFontSize,
        'font-family': this.buttonTextFontFamily && this.buttonTextFontFamily !== '' ? this.buttonTextFontFamily : defaultButtonFontFamily,
        'background-color': this.buttonBackgroundColor && this.buttonBackgroundColor !== '' ? this.buttonBackgroundColor : defaultButtonBackgroundColor,
        padding: this.buttonPadding && this.buttonPadding !== '' ? this.buttonPadding : defaultButtonPadding,
        border: this.buttonBorder && this.buttonBorder !== '' ? this.buttonBorder : defaultButtonBorder,
        'border-radius': this.buttonBorderRadius && this.buttonBorderRadius !== '' ? this.buttonBorderRadius : defaultButtonBorderRadius,
        'box-shadow': this.shadowButton && this.buttonBoxShadow && this.buttonBoxShadow !== '' ? this.buttonBoxShadow : defaultButtonBoxShadow,
        width: 'max-content'
      };
      if (this.isHovering) {
        theStyle['background-color'] = this.buttonHoverBackgroundColor && this.buttonHoverBackgroundColor !== '' ? this.buttonHoverBackgroundColor : defaultButtonHoverBackgroundColor;
        theStyle.cursor = 'pointer';
      }
      if (!this.buttonEnabled) {
        theStyle['box-shadow'] = this.buttonDisabledBoxShadow && this.buttonDisabledBoxShadow !== '' ? this.buttonDisabledBoxShadow : defaultDisabledButtonBoxShadow;
        theStyle['color'] = this.buttonDisabledTextFontColor && this.buttonDisabledTextFontColor !== '' ? this.buttonDisabledTextFontColor : defaultDisabledButtonColor;
      }
      return theStyle
    }
  },
}

</script>


<style scoped>
.button--scoped {
  display: inline-block;
  outline: none;
  border: none;
}
</style>
