<template>
  <div class="AppDropdownContainingDiv--scoped"
       v-on-clickaway="hideTheContent">
    <AppButton
        :clickFunction=toggleTheContent
        :buttonText="dropdownButtonText"
        :buttonTextFontColor="dropdownButtonTextColor"
        :buttonTextFontSize="dropdownButtonTextFontSize"
        :buttonTextFontFamily="dropdownButtonTextFontFamily"
        :buttonBackgroundColor="dropdownButtonBackground"
        :buttonHoverBackgroundColor="dropdownHoverButtonBackground"
        :buttonPadding="dropdownButtonPadding"
        :buttonBorder="dropdownButtonBorder"
        :buttonBorderRadius="dropdownButtonBorderRadius"
        :buttonBoxShadow="dropdownButtonBoxShadow"
        :buttonIconName="dropdownIconName"
        :buttonIconHeight="dropdownIconHeight"
        :buttonIconPadding="dropdownIconPadding"
    />
    <div :class="['dropdownContent--scoped',
                  { 'show':showContent}]"
         :style="calculatedDropdownContentContainerStyle()">
      <div
          v-for="{clickFunction, key, data, label, type, addMarkAfter, checked} in dropdownItems"
          :key="key"
          :style="calculateDropdownContentStyle(key, type)"
          @mouseover="doDropdownHover(key)"
          @mouseleave="doDropdownLeave(key)"
          @click="type === 'checkbox'? doNothing : doClickNotCheckBox(clickFunction, key)"
          @change="type === 'checkbox'? clickFunction() : doNothing"
      >
        <input v-if="type==='checkbox'"
                 type="checkbox"
                 :checked="checked"
          >
         <span :value="data"
                 @click.prevent="() => $emit('clickedCheckbox', data)"
         ></span> {{ label }}
         <span v-if="addMarkAfter && addMarkAfter !== ''">{{ addMarkAfter }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/Buttons/AppButton";
import {directive as onClickaway} from "vue-clickaway";


// Bunch of constants
const defaultContentWidth = "150px";
const defaultDropdownContainerPadding = 'none';
const defaultDropdownContentShiftDown = "30px";
const defaultDropdownContentShiftRight = "-105px";
const defaultDropdownBackgroundColor = "#ddd";
const defaultDropdownContentHeight = "200px";
const defaultDropdownContentPadding = "none";
const defaultDropdownContentFontSize = "1rem";
const defaultDropdownContentFontColor = "black";
const defaultDropdownContentFontFamily = "sans-serif";
const defaultDropdownButtonPadding = "none";
const defaultDropdownIconName = "doubleArrowDown-white.png"
const defaultDropdownIconHeight = 12;
const defaultIconPadding = "none";
const defaultDropdownButtonBorder = "none";
const defaultButtonBorderRadius = "none";
const defaultButtonBoxShadow = "none";
const defaultDropdownContainerBorder = "none";


export default {
  data: () => {
    return {
      ddStyle: {},
      addMarkAfter: {},
      disabled: {},
      showContent: false,
      dropdownHover: false,
      firstShow: true,
      flagNow: undefined,
    }
  },
  components: {
    AppButton
  },
  props: {
    // updateFlag is a prop to trigger updates due to enabling and disabling buttons
    // you must set updateFlag = !updateFlag to trigger re-render
    updateFlag: {
      type: Boolean,
      required: false,
      default: false
    },
    dropdownButtonText: {
      type: String,
      required: false,
      default: 'dropdown'
    },
    dropDownSelectGroup: {
      type: Object,
      required: false,
      default: undefined
    },
    // dropdownItems of the form
    //   {clickFunction, key, label, type, addMarkAfter, checked, disabled}
    //  First 4 are required and others optional
    //  type:  'checkbox'  |  <anything else>
    dropdownItems: {
      type: Array,
      required: true
    },
    dropdownButtonTextColor: {
      type: String,
      required: false,
      default: "black"
    },
    dropdownButtonTextFontSize: {
      type: String,
      required: false,
      default: "1rem"
    },
    dropdownButtonTextFontFamily: {
      type: String,
      required: false,
      default: "sans-serif"
    },
    dropdownButtonBackground: {
      type: String,
      required: false,
      default: "lightgray"
    },
    dropdownHoverButtonBackground: {
      type: String,
      required: false,
      default: "lightgray"
    },
    dropdownButtonPadding: {
      type: String,
      required: false,
      default: defaultDropdownButtonPadding
    },
    dropdownButtonBorder: {
      type: String,
      required: false,
      default: defaultDropdownButtonBorder
    },
    dropdownButtonBorderRadius: {
      type: String,
      required: false,
      default: defaultButtonBorderRadius
    },
    dropdownButtonBoxShadow: {
      type: String,
      required: false,
      default: defaultButtonBoxShadow
    },
    dropdownIconName: {
      type: String,
      required: false,
      default: defaultDropdownIconName
    },
    dropdownIconHeight: {
      type: Number,
      required: false,
      default: defaultDropdownIconHeight
    },
    dropdownIconPadding: {
      type: String,
      required: false,
      default: defaultIconPadding
    },
    dropdownWidth: {
      type: String,
      required: false,
      default: defaultContentWidth
    },
    dropdownContainerPadding: {
      type: String,
      required: false,
      default: defaultDropdownContainerPadding
    },
    dropdownContentHeight: {
      type: String,
      required: false,
      default: defaultDropdownContentHeight
    },
    dropdownContentPadding: {
      type: String,
      required: false,
      default: defaultDropdownContentPadding
    },
    dropdownContainerBackgroundColor: {
      type: String,
      required: false,
      default: defaultDropdownBackgroundColor
    },
    dropdownContainerBorder: {
      type: String,
      required: false,
      default: defaultDropdownContainerBorder
    },
    dropdownContentBackgroundColor: {
      type: String,
      required: false,
      default: defaultDropdownBackgroundColor
    },
    dropdownContentDisabledBackgroundColor: {
      type: String,
      required: false,
      default: defaultDropdownBackgroundColor
    },
    dropdownContentHoverColor: {
      type: String,
      required: false,
      default: defaultDropdownBackgroundColor
    },
    dropdownContentDisabledHoverColor: {
      type: String,
      required: false,
      default: defaultDropdownBackgroundColor
    },
    dropdownContentFontColor: {
      type: String,
      required: false,
      default: defaultDropdownContentFontColor
    },
    dropdownContentDisabledFontColor: {
      type: String,
      required: false,
      default: defaultDropdownContentFontColor
    },
    dropdownContentFontSize: {
      type: String,
      required: false,
      default: defaultDropdownContentFontSize
    },
    dropdownContentFontFamily: {
      type: String,
      required: false,
      default: defaultDropdownContentFontFamily
    },
    dropdownContentShiftDown: {
      type: String,
      required: false,
      default: defaultDropdownContentShiftDown
    },
    dropdownContentShiftRight: {
      type: String,
      required: false,
      default: defaultDropdownContentShiftRight
    },
  },
  methods: {
    toggleTheContent: function () {
      this.showContent = !this.showContent;
    },
    hideTheContent: function () {
      this.showContent = false;
    },

    doNothing: function () {
    },
    doClickNotCheckBox: function (clickFunction, key) {
      clickFunction()
      const resetHoverColor = () => {
        this.ddStyle[key]['background-color'] = this.dropdownContentHoverColor
      };
      this.ddStyle[key]['background-color'] = this.dropdownContentBackgroundColor;
      setTimeout(resetHoverColor, 150);
    },
    doDropdownHover(key) {
      if (!this.ddStyle[key] || !this.disabled[key]) {
        this.initialDisabled();
        this.initialDDStyle();
      }
      this.ddStyle[key]['background-color'] = !this.disabled[key] ?
          this.dropdownContentHoverColor : this.dropdownContentDisabledHoverColor;
      this.ddStyle[key].cursor = 'pointer';
    },
    doDropdownLeave(key) {
      if (!this.ddStyle[key] || !this.disabled[key]) {
        this.initialDisabled();
        this.initialDDStyle();
      }
      this.ddStyle[key]['background-color'] = !this.disabled[key] ?
          this.dropdownContentBackgroundColor : this.dropdownContentDisabledBackgroundColor;
      this.ddStyle[key].cursor = 'pointer';
    },

    calculatedDropdownContentContainerStyle() {
      if (this.flagNow !== this.updateFlag) {
        this.flagNow = this.updateFlag;
        this.initialDisabled();
        this.initialDDStyle();
      }
      return {
        'z-index': 75,
        position: 'absolute',
        width: this.dropdownWidth && this.dropdownWidth !== '' ? this.dropdownWidth : defaultContentWidth,
        top: this.dropdownContentShiftDown && this.dropdownContentShiftDown !== '' ?
            this.dropdownContentShiftDown : defaultDropdownContentShiftDown,
        left: this.dropdownContentShiftRight && this.dropdownContentShiftRight !== '' ?
            this.dropdownContentShiftRight : defaultDropdownContentShiftRight,
        backgroundColor: this.dropdownContainerBackgroundColor && this.dropdownContainerBackgroundColor !== '' ?
            this.dropdownContainerBackgroundColor : defaultDropdownBackgroundColor,
        height: this.dropdownContentHeight && this.dropdownContentHeight !== '' ?
            this.dropdownContentHeight : defaultDropdownContentHeight,
        padding: this.dropdownContainerPadding && this.dropdownContainerPadding !== '' ?
            this.dropdownContainerPadding : defaultDropdownContainerPadding,
        border: this.dropdownContainerBorder && this.dropdownContainerBorder !== '' ?
            this.dropdownContainerBorder : defaultDropdownContainerBorder
      }
    },
    calculateDropdownContentStyle(key, type) {
      const theStyle = this.ddStyle[key];
      if (type === 'userName') {
        theStyle['font-style'] = 'italic'
      }
      return theStyle;
    },
    initialDDStyle() {
      for (let i = 0; i < this.dropdownItems.length; i++) {
        this.$set(this.ddStyle, this.dropdownItems[i].key, {
          display: 'inline-block',
          'font-size': this.dropdownContentFontSize,
          'color': !this.disabled[this.dropdownItems[i].key] ?
              this.dropdownContentFontColor : this.dropdownContentDisabledFontColor,
          'font-family': this.dropdownContentFontFamily,
          'background-color': !this.disabled[this.dropdownItems[i].key] ?
              this.dropdownContentBackgroundColor : this.dropdownContentDisabledBackgroundColor,
          padding: this.dropdownContentPadding,
        })
      }
    },
    initialDisabled() {
      for (let i = 0; i < this.dropdownItems.length; i++) {
        this.$set(this.disabled, this.dropdownItems[i].key, !!this.dropdownItems[i].disabled)
      }
    },

  },
  watch: {
    showContent: function () {
      if (this.firstShow) {
        this.firstShow = false;
        this.initialDDStyle();
      }
    }
  },
  directives: {
    onClickaway: onClickaway,
  },

  mounted() {
    this.initialDisabled()
    this.initialDDStyle()
  },

}

</script>


<style scoped>
.AppDropdownContainingDiv--scoped {
  display: inline-block;
  position: relative;
}


.dropdownContent--scoped {
  display: none;
  position: absolute;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
  justify-content: space-between;
  z-index: 1;
}

.dropdownContent--scoped.show {
  display: flex;
}


</style>
