<template>
  <div class="modal-mask"
       v-show="showModal">
    <div class="modal-wrapper"
         :style="getStyleWrapper">
      <div class="modal-container"
           :style="getStyleContainer">
        <div :style="getStyleHeader">
          <slot name="header">
          </slot>
        </div>
        <div :style="getStyleBody">
          <slot name="body">
            default body
          </slot>
        </div>
        <div :style="getStyleFooter">
          <slot name="footer">
            default footer
            <button @click="$emit('close')">
              OK
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data: () => {
    return {
      clickOutside: false,
    }
  },

  props: {
    showModal: {
      type: Boolean,
      required: false,
      default: false
    },
    position: {
      type: String,
      required: false,
      default: 'absolute'
    },
    top: {
      type: String,
      required: false,
      default: '0'
    },
    left: {
      type: String,
      required: false,
      default: '0'
    },
    containerPadding: {
      type: String,
      required: false,
      default: "10px 20px"
    },
    containerWidth: {
      type: String,
      required: false,
      default: "280px"
    },
    borderRadius: {
      type: String,
      required: false,
      default: "2px"
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "#fff"
    },
    boxShadow: {
      type: String,
      required: false,
      default: "0 2px 8px rgba(0, 0, 0, 0.33)"
    },
    fontFamily: {
      type: String,
      required: false,
      default: "sans-serif"
    },
    headFontSize: {
      type: String,
      required: false,
      default: "1rem"
    },
    headFontColor: {
      type: String,
      required: false,
      default: "black"
    },
    bodyFontSize: {
      type: String,
      required: false,
      default: "1rem"
    },
    bodyFontColor: {
      type: String,
      required: false,
      default: "black"
    },
    footerFontSize: {
      type: String,
      required: false,
      default: "1rem"
    },
    footerFontColor: {
      type: String,
      required: false,
      default: "black"
    },
  },

  methods: {
  },

  computed: {
    getStyleWrapper() {
      return {
        position: this.position,
        top: this.top,
        left: this.left,
      }
    },
    getStyleContainer() {
      return {
        padding: this.containerPadding,
        width: this.containerWidth,
        'background-color': this.backgroundColor,
        'border-radius': this.borderRadius,
        'box-shadow': this.boxShadow,
        'font-family': this.fontFamily,
      }
    },
    getStyleHeader() {
      return {
        'font-size': this.headFontSize,
        'color': this.headFontColor,
      }
    },
    getStyleBody() {
      return {
        'font-size': this.bodyFontSize,
        'color': this.bodyFontColor,
      }
    },
    getStyleFooter() {
      return {
        'font-size': this.footerFontSize,
        'color': this.footerFontColor,
      }
    },
  },
}
</script>

<style scoped>
.modal-mask {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: table;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: top;
}

.modal-container {
  margin: 0px auto;
}

</style>