<template>
  <RegularHelpPage
    :AppThemeSetting="AppThemeSetting"
    :nameOfPage="'ArchivingPage'"
  />
</template>

<script>
import RegularHelpPage from "@/components/collector-parts/RegularHelpPage";

export default {
  data: () => {
    return {
    }
  },

  components: {
    RegularHelpPage,
  },

  props: {
    AppThemeSetting: {
      type: String,
      required: false,
      default: "themeOne"
    },
  },

}
</script>
