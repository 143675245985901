<template>
  <div :style="{width:'100%', height:'100%', 'min-height':'100%'}">
    <AppBackground :AppThemeSetting="AppThemeSetting"></AppBackground>

    <TheAppNavbar :additionalNavbarDropdownLinks="getAdditionalDropdownItems"
                  :AppThemeSetting="AppThemeSetting"/>

    <slot name="mainRegionContent" :style="getStyleMainRegion">Default mainRegionContent content</slot>
  </div>
</template>

<script>
// Note: to add local storage, replace
//    :additionalNavbarDropdownLinks=[]
// with:
//     :additionalNavbarDropdownLinks="useLocalStorageDropdownLinks?
//                                this.getLocalStorageDropdownLinks : []"

import TheAppNavbar from "@/components/navbar-toolpanel/TheAppNavbar";
import AppBackground from "@/components/collector-parts/AppBackground";
import config from "../../config/config-css";
import checkMobileMixin from '../../mixins/CheckMobileMixin_MX.js';

export default {
  data: () => {
    return {
      config,

      someMessage: "I'm a message"
    }
  },
  mixins: [checkMobileMixin],

  props: {
    AppThemeSetting: {
      type: String,
      required: false,
      default: "themeOne"
    },
    useLocalStorageDropdownLinks: {
      type: Boolean,
      required: false,
      default: true
    },
  },

  components: {
    TheAppNavbar, AppBackground
  },

  computed: {
    getAdditionalDropdownItems() {
      return []
    },

    getStyleMainRegion() {
      return {
        height: '100%',
        'background-color': 'yellow',
      }
    }
  }
}
</script>

<style>
body {
  height: 100%;
}
</style>