<template>
  <div>
    <div
        :class="['navbar--scoped']"
        :style="navbarCalculatedStyle">
      <div :class="['navbar__items--scoped']"
           :style="getNavbarLogoStyle">
        <img
            :src="getImgUrl(this.navbarLogo)"
            alt="navbarLogoAltName"
            :class="['navbar__logo--scoped']"
            :height="navbarLogoHeight"
            @click="logoClicked">
      </div>
      <div v-for="{clickFunction, key, label} in leftLinksTop"
           :key="key"
           :class="['navbar__items--scoped']"
           :style="llStyle[key]"
           @mouseover="doLeftLinkHover(key)"
           @mouseleave="doLeftLinkLeave(key)"
           @click="clickFunction">
        {{ label }}
      </div>
      <span class="containsAppDropdown--scoped" :style="{'padding-right': dropdownButtonAllShiftFromRight}">
        <AppDropdown
            :dropdownButtonText="''"
            :dropdownItems="dropdownItemsRegularAndCollapse"

            :dropdownButtonTextColor="''"
            :dropdownButtonBackground="dropdownButtonBackgroundColor"
            :dropdownHoverButtonBackground="dropdownButtonBackgroundColor"
            :dropdownButtonPadding="dropdownButtonPadding"
            :dropdownIconName="dropdownIconName"
            :dropdownIconHeight="dropdownIconHeight"
            :dropdownWidth="dropdownWidth"
            :dropdownContentHeight="dropdownContentHeightToUse"
            :dropdownContentBackgroundColor="dropdownContentBackgroundColor"
            :dropdownContentHoverColor="dropdownContentHoverColor"
            :dropdownContentShiftDown="dropdownContentShiftDown"
            :dropdownContentShiftRight="dropdownContentShiftRight"

            :dropdownContainerPadding="dropdownContainerPadding"
            :dropdownContainerBorder="dropdownContainerBorder"

            :dropdownButtonBoxShadow="dropdownButtonBoxShadow"

            :dropdownContentPadding="dropdownContentPadding"
            :dropdownContentFontSize="dropdownContentFontSize"
            :dropdownContentFontColor="dropdownContentFontColor"
            :dropdownContentFontFamily="dropdownContentFontFamily"
        />
      </span>
    </div>
    <div v-if="navbarFixedTop" class="navbar__paddingDivBelow--scoped" :style="navbarBelowPaddingStyle"></div>
  </div>
</template>


<script>
import AppDropdown from "@/components/Buttons/AppDropdown";
import checkMobileMixin from '../../mixins/CheckMobileMixin_MX.js';


export default {
  data: () => {
    return {
      llStyle: {},
      ddStyle: {}
    }
  },
  mixins: [checkMobileMixin],
  props: {
    navbarFixedTop: {
      type: Boolean,
      required: false,
      default: true
    },
    navbarHeight: {
      type: Number,
      required: false,
      default: 64
    },
    navbarLogo: {
      type: String,
      required: false,
      default: "NFLogo.png"
    },
    navbarLogoHeight: {
      type: Number,
      required: false,
      default: 64
    },
    navbarLogoPadding: {
      type: String,
      required: false,
      default: "0 0 0 0"
    },
    logoClicked: {
      type: Function,
      required: false,
      default: function () {
      }
    },

    leftLinks: {
      type: Array,
      required: false,
      default: () => []
    },
    defaultDropdownLinks: {
      type: Array,
      required: false,
      default: () => []
    },

    navbarBackgroundColor: {
      type: String,
      required: false,
      default: '#861641'
    },
    navbarBorderBottom: {
      type: String,
      required: false,
      default: 'gray 2px solid'
    },

    leftLinksFontFamily: {
      type: String,
      required: false,
      default: "sans-serif"
    },
    leftLinksFontSize: {
      type: String,
      required: false,
      default: '1.1rem'
    },
    leftLinksFontColor: {
      type: String,
      required: false,
      default: '#A9B5A2'
    },
    leftLinksHoverColor: {
      type: String,
      required: false,
      default: '#008d5e'
    },

    dropdownIconHeight: {
      type: Number,
      required: false,
      default: 35
    },
    dropdownIconName: {
      type: String,
      required: false,
      default: 'doubleArrowDown-black.png'
    },
    dropdownButtonBackgroundColor: {
      type: String,
      required: false,
      default: '#D3D3D3'
    },
    dropdownButtonPadding: {
      type: String,
      required: false,
      default: "5px 5px 5px 5px"
    },
    dropdownButtonAllShiftFromRight: {
      type: String,
      required: false,
      default: '24px'
    },
    dropdownButtonBoxShadow: {
      type: String,
      required: false,
      default: 'none'
    },
    dropdownWidth: {
      type: String,
      required: false,
      default: "200px"
    },
    dropdownContainerPadding: {
      type: String,
      required: false,
      default: '0 24px 0 16px'
    },
    dropdownContainerBorder: {
      type: String,
      required: false,
      default: 'none'
    },

    dropdownContentHeight: {
      type: String,
      required: false,
      default: "auto"
    },
    dropdownContentHeightCollapse: {
      type: String,
      required: false,
      default: "auto"
    },
    dropdownContentFontFamily: {
      type: String,
      required: false,
      default: "sans-serif"
    },
    dropdownContentFontSize: {
      type: String,
      required: false,
      default: '1.25rem'
    },
    dropdownContentFontColor: {
      type: String,
      required: false,
      default: '#A9B5A2'
    },
    dropdownContentHoverColor: {
      type: String,
      required: false,
      default: '#ccc'
    },
    dropdownContentBackgroundColor: {
      type: String,
      required: false,
      default: '#D3D3D3'
    },
    dropdownContentPadding: {
      type: String,
      required: false,
      default: "5px 0 5px 15px"
    },
    dropdownContentShiftDown: {
      type: String,
      required: false,
      default: "30px"
    },
    dropdownContentShiftRight: {
      type: String,
      required: false,
      default: "-162px"
    }
  },
  components: {
    AppDropdown
  },
  methods: {
    getImgUrl(pic) {
      if (!pic || pic === '') {
        return false
      } else {
        return require("@/assets/imgs/" + pic)
      }
    },
    doLeftLinkHover(key) {
      this.llStyle[key].color = this.leftLinksHoverColor;
      this.llStyle[key].cursor = 'pointer';
    },
    doLeftLinkLeave(key) {
      this.llStyle[key].color = this.leftLinksFontColor;
      this.llStyle[key].cursor = 'default';

    },
  },
  computed: {
    getNavbarLogoStyle() {
      let theStyle = {'padding': this.navbarLogoPadding};
      return theStyle;
    },
    navbarBelowPaddingStyle() {
      const theStyle = {
        'height': Math.floor(this.navbarHeight) + 'px',
        'width': this.windowWidth + 'px'
      };
      return theStyle
    },
    navbarCalculatedStyle() {
      const theStyle = {
        'height': this.navbarHeight + 'px', 'background-color': this.navbarBackgroundColor,
        'border-bottom': this.navbarBorderBottom
      };
      if (this.navbarFixedTop) {
        theStyle.position = 'fixed';
        theStyle.top = 0;
        theStyle.left = 0;
        theStyle['z-index'] = 100;
        theStyle['width'] = this.windowWidth + 'px';
      }
      return theStyle
    },
    leftLinksTop() {
      return this.isMobile ? [] : this.leftLinks
    },
    leftLinksDropdown() {
      return this.isMobile ? this.leftLinks : []
    },
    dropdownContentHeightToUse() {
      return this.isMobile ? this.dropdownContentHeightCollapse : this.dropdownContentHeight
    },
    dropdownItemsRegularAndCollapse() {
      return !this.isMobile ? this.defaultDropdownLinks : [...this.leftLinks, ...this.defaultDropdownLinks];
    },
  },

  created() {
    for (let i = 0; i < this.leftLinks.length; i++) {
      this.$set(this.llStyle, this.leftLinks[i].key, {
        'font-size': this.leftLinksFontSize,
        'color': this.leftLinksFontColor,
        'font-family': this.leftLinksFontFamily,
      });
    }
  }
}
</script>


<!--******************-->
<!--Styles begin here-->
<!--******************-->
<style scoped>
.containsNavbar--scoped {
}

.navbar__paddingDivBelow--scoped {
  display: block;
  width: 100%;
}

.navbar--scoped {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
}

.navbar__logo--scoped {
  padding-right: 16px;
  padding-left: 4px;
  display: inline-block;
}

.containsAppDropdown--scoped {
  display: inline-block;
  margin-left: auto;
}

.navbar__items--scoped {
  display: inline-block;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0px;
}

</style>