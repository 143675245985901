<template>
  <TheAppBaseView>
    <template v-slot:mainRegionContent>
      <HelpPageTitle
          :AppThemeSetting="AppThemeSetting"
          :titleText="configCss.mainTitle"
      />
      <SectionBlock
          :AppThemeSetting="AppThemeSetting"

          :sectionTitle="configCss.contentsTitle"

          :isHide="isHideMap['mainContents']"
          @clickHide="isHideMap['mainContents']=true"
          @clickShow="isHideMap['mainContents']=false"
      >
        <template v-slot:sectionContent>
          <ContentItem
              v-for="item in configCssCPL.items"
              :key="item.name"
              :AppThemeSetting="AppThemeSetting"
              :routerPageName="item.name"
              :linkLabel="item.linkLabel"
              :linkDescription="item.linkDescription"
          />
        </template>
      </SectionBlock>
      <SpacingBetween/>
    </template>
  </TheAppBaseView>
</template>

<script>
// import Vue from 'vue'
import TheAppBaseView from "@/components/collector-components/TheAppBaseView";
import SectionBlock from "@/components/collector-parts/SectionBlock";
import HelpPageTitle from "@/components/collector-parts/HelpPageTitle";
import SpacingBetween from "@/components/collector-parts/SpacingBetween";

import config from '@/config/config-css.js'
import CheckMobileMixinMX from "@/mixins/CheckMobileMixin_MX";
import router from "@/router";
import ContentItem from "@/components/collector-parts/ContentItem";

export default {
  data: () => {
    return {
      config,

      isHideMap: {
        mainContents: false,
      },

      // show flags
    }
  },

  mixins: [CheckMobileMixinMX],
  components: {
    ContentItem,
    SpacingBetween,
    TheAppBaseView, SectionBlock, HelpPageTitle,
  },

  props: {
    AppThemeSetting: {
      type: String,
      required: false,
      default: "themeOne"
    },
  },

  methods: {
    goToPage(name) {
      if (router.currentRoute.name === name) {
        window.scrollTo(0, 0)
      } else {
        router.push({
          name: name,
          params: {
            AppThemeSetting: this.AppThemeSetting
          }
        });
        window.scrollTo(0, 0)
      }
    }
  },

  computed: {
    getStyleContentSection() {
      return {color: 'white', background: 'green'}
    },
    getStyleLink() {
      return {
        cursor: 'pointer',
        color: 'blue',
        'text-decoration': 'underline',
      }
    },

    configCss() {
      return this.config[this.AppThemeSetting].HomePage
    },
    configCssCPL() {
      return this.config[this.AppThemeSetting].ContentPageLinks
    },
    configCssAll() {
      return this.config[this.AppThemeSetting].appAllUnits
    },
  },

  created() {
    this.$nextTick(async function () {
    })
  }
}
</script>
