<template>
  <div :style="getStyleTitle">
    {{ titleText }}
  </div>
</template>

<script>
import config from '@/config/config-css.js'

export default {
  data: () => {
    return {
      config
    }
  },

  props: {
    AppThemeSetting: {
      type: String,
      required: false,
      default: "themeOne"
    },
    titleText: {
      type: String,
      required: false,
      default: 'Default title'
    }
  },

  computed: {
    getStyleTitle() {
      return {
        padding: this.configCss.padding,
        'color': this.configCss.fontColor,
        'font-family': this.configCss.fontFamily,
        'font-size': this.configCss.fontSize,
        'background': this.configCss.background,
        width: this.configCss.width,
      }
    },

    configCss() {
      return this.config[this.AppThemeSetting].HelpPageTitle
    },
    configCssAll() {
      return this.config[this.AppThemeSetting].appAllUnits
    },

  }
}
</script>