<template>
  <div :style="getStyleSpaceBetween"></div>
</template>

<script>
import config from '@/config/config-css.js'

export default {
  data: () => {
    return {
      config
    }
  },

  props: {
    AppThemeSetting: {
      type: String,
      required: false,
      default: "themeOne"
    },
  },

  computed: {
    getStyleSpaceBetween() {
      return {
        height:this.configCssAll.interSectionSpacing,
        background: this.configCssAll.interSectionBackground
      }
    },

    configCssAll() {
      return this.config[this.AppThemeSetting].appAllUnits
    },

  }
}
</script>