// NO SLASH AT THE END
const environment = process.env.VUE_APP_ENVIRONMENT || "testing";
const referenceDate = new Date(2021,0,27,10,30);

export default {
    version: "1.0.01",
    environment,
    referenceDate,
    testing: {
        identifyUrl: 'http://localhost:4000',
    },
    development: {
        identifyUrl: 'http://localhost:4000',
    },
    production: {
        identifyUrl: 'https://dispatcher-01.mygradesonline.com',
    }
};
