const mgoColors = {
    c1: "#F2FAF1",
    c1bl: "#c4d1ea",
    c1h: "#b1b8b1",
    c1d: "#b1b8b1",
    c1db: "#d3ddd3",
    c1dd: "#7c817c",
    c1hh: "#cdfdca",
    c1dh: "#c0cfc0",


    c2: "#2e4469",
    c2Alt1: "#3c4c67",
    c2Alt2: "#555d6b",
    c2Alt3: "#595d70",
    c2l: "#283d5a",
    c2d: "#162232",
    c2dh: "#303753",
    c2da: "#22354e",
    c2h: "#44639d",
    c2hb: "#394a67",
    c2a: "rgba(46,68,105,0.85)",
    c3: "#5D8DAC",
    c3b: "#3577a2",
    c3c: "#1f86c1",
    c3d: "#76b7e0",
    c4: "#FF3E4A",
    c4alt1: "#FF3E4A",
    c4d: "#d0454c",
    c4h: "#fa9198",
    c5: "#EE5259",

    c6: '#2C5F1C',
    c6b: '#3d7c2e',
    c6da: "#62cb4a",
    c7: '#59BE37',
    c7h: '#92c384',

    c8: "#4b586b",
    c8h: "#889196",

    c9: "#3f6596",
    c9h: "#678099",

    c10: "#e1e1e2",
    c10Alt1: "#cdcdce",
    c10Alt2: "#e1e1e2",

    c11: '#F94144',
    c11Alt1: '#dd393c',
    c11h: '#f56f71',
    c11d: '#a92d30',
    c12: '#90BE6D',
    c12Alt1: '#8cc660',
    c12h: '#5e7252',
    c12d: '#425632',

    c13: '#fa9f32',
    c13h: '#fad3a7',

    c14: '#fcfc2d',
    c14h: '#fdfda3',

    cwarn: "#563002",
    cclear: "#104a06",

};

export default {
    themeOne: {
        appAllUnits: {
            appBackgroundColor: mgoColors.c2d,
            overallBackgroundColor: mgoColors.c2,

            sectionBlocksBackgroundColor: mgoColors.c2,
            sectionBlocksFontColor: mgoColors.c1,
            sectionBlocksMargins: '0 0 8px 0',

            descriptionAreaBackgroundColor: mgoColors.c2da,
            descriptionAreaFontColor: mgoColors.c1,
            descriptionTextWidthInPx: 800,
            descriptionAreaFigureHeightInPx: 300,
            descriptionAreaVideoHeightInPx: 600,
            descriptionPadding: '16px 0 16px 12px',
            descriptionTextPadding: '16px 0 0 0',

            interSectionSpacing: '8px',
            interSectionBackground: mgoColors.c2Alt1,
            spaceAtEnd: '32px',

            hideButton: {
                hideButtonTextHide: 'hide',
                hideButtonTextShow: 'show',
                hideButtonPadding: '0 0 0 4px',
                textColorHide: mgoColors.c2d,
                backgroundColorHide: mgoColors.c13,
                backgroundColorHideHover: mgoColors.c13h,
                textColorShow: mgoColors.c2d,
                backgroundColorShow: mgoColors.c14,
                backgroundColorShowHover: mgoColors.c14h,
                textFontSize: '1rem',
                textFontFamily: 'sans-serif',
                border: '1px ' + mgoColors.c2d + ' solid',
                borderRadius: '8px',
                boxShadow: '1px 2px 6px ' + mgoColors.c2,
                disabledTextFontColor: mgoColors.c1,
                disabledBoxShadow: 'none',
            },
            appButtonTheme: {
                buttonText: 'infer users',
                buttonId: 'appButtonForInferUsers',
                textPadding: "2px 16px 2px 16px",
                textColor: mgoColors.c1,
                disabledTextFontColor: mgoColors.c1d,
                backgroundColor: mgoColors.c2,
                hoverBackgroundColor: mgoColors.c2h,
                border: "1px " + mgoColors.c2d + " solid",
                boxShadow: "2px 2px 6px " + mgoColors.c2l,
                disabledBoxShadow: "none",
                textFontSize: "1rem",
                textFontFamily: "sans-serif",
                borderRadius: "8px",
            },
            appToolsSelectionList: {
                height: '30px',
                titleFontColor: mgoColors.c1,
                titleBackgroundColor: mgoColors.c3,
                titleFontWeight: "800",
                titleMobileFontWeight: "600",
                titleFontSize: '1rem',
                titleFontFamily: 'sans-serif',
                titlePadding: '0 8px 0 0',
                titleMobilePadding: '0 0 0px 0',
                buttonAddText: "Add",
                buttonDeleteText: "Delete",
                overallContainerPadding: "4px 0 0 0",
                textAndButtonContainerPadding: "4px 8px 4px 8px",
                textAndButtonContainerHeightOpen: "25px",
                textAndButtonContainerHeightClosed: "50px",
                overallWidth: '98%',
                mobileOverallWidth: "98%",
                titleBlockBackgroundColor: mgoColors.c3,
                view: {
                    titleFontColor: mgoColors.c2,
                    titleFontSize: '1rem',
                    titleMobileFontSize: '1rem',
                    titleFontFamily: 'sans-serif',
                    titleBackground: 'auto',
                    border: 'none',
                    borderRadius: 'none',
                    width: '70%',
                },
                edit: {
                    deleteButtonRightPadding: "0 0 0 2px",
                    deleteButtonMobileRightPadding: "0 0 0 2px",
                    addButtonLeftPadding: '0 0 0 128px',
                    addButtonMobileLeftPadding: '0 0 0 128px',
                    titleFontColor: mgoColors.c2,
                    titleFontSize: '1rem',
                    titleMobileFontSize: '1rem',
                    titleFontFamily: 'sans-serif',
                    titleBackground: 'auto',
                    border: 'none',
                    addButtonBorder: '1px ' + mgoColors.c2 + ' solid',
                    deleteButtonBorder: '1px ' + mgoColors.c2 + ' solid',
                    borderRadius: 'none',
                    width: '70%',
                },
            },
            appButtonForSelectionListPanel: {
                buttonAddTextFontColor: mgoColors.c1,
                buttonAddTextDisabledFontColor: mgoColors.c1db,
                buttonAddBackgroundColor: mgoColors.c7,
                buttonAddHoverBackgroundColor: mgoColors.c7h,
                buttonDeleteTextFontColor: mgoColors.c1,
                buttonDeleteTextDisabledFontColor: mgoColors.c1db,
                buttonDeleteBackgroundColor: mgoColors.c11,
                buttonDeleteHoverBackgroundColor: mgoColors.c11h,

                thirdButtonBorder: "1px " + mgoColors.c2 + " solid",
                thirdButtonTextFontColor: mgoColors.c1,
                thirdButtonTextDisabledFontColor: mgoColors.c1db,
                thirdButtonBackgroundColor: mgoColors.c7,
                thirdButtonHoverBackgroundColor: mgoColors.c7h,

                buttonTextFontColor: mgoColors.c1,
                buttonTextFontSize: "1rem",
                buttonTextFontFamily: "sans-serif",

                buttonBackgroundColor: mgoColors.c2,
                buttonHoverBackgroundColor: mgoColors.c2h,
                buttonPadding: "1px 8px 1px 8px",
                buttonMobilePadding: "1px 4px 1px 4px",
                buttonBorder: "1px " + mgoColors.c1 + " solid",
                buttonBorderRadius: "8px",
                buttonBoxShadow: "1px 1px 4px " + mgoColors.c2,
                buttonDisabledBoxShadow: "none",
                buttonDisabledTextFontColor: "#777",
                buttonIconName: "",
                buttonIconHeight: 30,
                buttonIconPadding: "0 0 0 0px",
            },

            tableHeaderBackgroundColor: mgoColors.c2,
            tableHeaderFontColor: mgoColors.c1,
            titleBackgroundColor: mgoColors.c1,
            tableDataFontColor: mgoColors.c2d,
            tableDataEditBackgroundColor: 'white',
            tableDataEditFontColor: mgoColors.c2d,

            textViewBackgroundColor: mgoColors.c2,
            textViewFontColor: mgoColors.c2d,

            appModal: {
                containerPadding: "10px 20px",
                containerWidth: "280px",
                borderRadius: "8px",
                backgroundColor: mgoColors.c1,
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.33)",
                fontFamily: "sans-serif",
                headFontSize: "1.2rem",
                headFontWeight: "400",
                headFontColor: mgoColors.c1,
                headBackgroundColor: mgoColors.c2,
                bodyFontSize: "1rem",
                footerFontSize: "1rem",
                buttonTextFontSize: "1rem",
                buttonTextFontFamily: "sans-serif",
                buttonPadding: "2px 4px 2px 4px",
                buttonBorder: '1px ' + mgoColors.c2 + ' solid',
                buttonBorderRadius: "8px",
                buttonBoxShadow: "1px 2px 6px " + mgoColors.c2,
                footerFontColor: mgoColors.c2,
                bodyFontColor: mgoColors.c2,

                subtitleFontColor: mgoColors.c2d,
                subtitleFontSize: '16px',
                subtitlePadding: '4px 0 4px 0',

                ok: {
                    modalOKTop: '120px',
                    modalOKNarrowTop: '460px',
                    modalOKLeft: '0',
                    modalOKNarrowLeft: '0',

                    position: 'relative',
                    top: '120px',
                    left: '0',

                    buttonOKTextFontColor: mgoColors.c1,
                    buttonOKBackgroundColor: mgoColors.c9,
                    buttonOKHoverBackgroundColor: mgoColors.c9h,

                    buttonIconName: "none",
                    buttonIconHeight: "''",
                    buttonIconPadding: "'none'",
                },
                delete: {
                    position: 'relative',
                    top: '120px',
                    left: '0',

                    buttonDeleteTextFontColor: mgoColors.c1,
                    buttonDeleteBackgroundColor: mgoColors.c11,
                    buttonDeleteHoverBackgroundColor: mgoColors.c11h,
                    buttonCancelTextFontColor: mgoColors.c1,
                    buttonCancelBackgroundColor: mgoColors.c8,
                    buttonCancelHoverBackgroundColor: mgoColors.c8h,

                    buttonIconName: "none",
                    buttonIconHeight: "''",
                    buttonIconPadding: "'none'",
                },
                setPassword: {
                    position: 'relative',
                    top: '120px',
                    left: '0',

                    inlineToggleFontColor: mgoColors.c2h,
                    inlineToggleContainerPadding: "8px 0 8px 0",

                    okButtonText: "Set New Password",
                    buttonOKTextFontColor: mgoColors.c1,
                    buttonOKTextDisabledFontColor: mgoColors.c1dd,
                    buttonOKBackgroundColor: mgoColors.c2,
                    buttonOKHoverBackgroundColor: mgoColors.c2h,

                    cancelButtonText: "Cancel",
                    buttonCancelTextFontColor: mgoColors.c1,
                    buttonCancelTextDisabledFontColor: mgoColors.c1dd,
                    buttonCancelBackgroundColor: mgoColors.c2,
                    buttonCancelHoverBackgroundColor: mgoColors.c2h,

                    inputPadding: '0 0 8px 0',
                    inputContainerHeight: '40px',
                    inputHeight: '28px',
                    inputFontSize: '20px',
                    borderInput: mgoColors.c3 + ' 2px solid',
                    borderInputRadius: '8px',
                },
            },
        },

        appTable: {
            overallTableBackgroundColor: mgoColors.c1,
            overallTitleBackgroundColor: mgoColors.c1,
            overallTitleFontColor: mgoColors.c2d,
            titleFontColor: mgoColors.c2d,
            titleBackgroundColor: mgoColors.c1,
            textViewBackgroundColor: mgoColors.c2,

            editText: "edit",
            editTextPanel: "edit list",

            inputViewFontColor: mgoColors.c2d,
            textViewFontColor: mgoColors.c2d,
            labelFontColor: mgoColors.c2d,

            backgroundColorHeader: mgoColors.c2,
            backgroundColorAlt1: mgoColors.c10Alt1, //mgoColors.c2Alt1,
            backgroundColorAlt2: mgoColors.c10Alt2, // mgoColors.c2Alt2,
            backgroundColorMarkForDelete: mgoColors.c11,
        },

        appInputTextSimple: {
            textEditFontSize: '1rem',
            textMobileFontSize: '1rem',
            textViewFontSize: '1rem',
            overallPadding: '0 0 0 0',
            paddingInputViewError: '4px 0 0 4px',
            paddingSideCheck: '4px 0 0 4px',
            textViewPadding: '0 0 0 0',
            textEditPadding: '2px 0 0 0',
            textMobilePadding: 'none',
            textFontFamily: 'sans-serif',
            borderRadius: '4px',
            boxShadow: 'none',
            inputWidth: '176px',
            minInputWidthInPX: 320,
            inputHeight: '24px',
            minInputHeightInPX: 36,
            outerWidth: '224px',
            height: '44px',
            textAlign: 'center',
            sortSymbolFontSize: '1rem',
            view: {
                minHeight: "2rem",
                maxHeight: "8rem",
                heightRow: "2rem",
                heightColumn: "3.25rem",
                textFontColor: mgoColors.c2d,
                textFontSize: '1rem',
                textMobileFontSize: '1rem',
                textFontFamily: 'sans-serif',
                textBackground: 'auto',
                textPadding: '0 0 0 8px',
                textMobilePadding: 'none',
            },
            edit: {
                inputWidth: '90%', // this is the width of the input for text
                textAreaInputHeight: '48px',
                heightRow: "auto",
                heightColumn: "auto",
                heightAdditionalForTextareaAdditionalRow: 1.15, // only applies for textarea in rems
                textFontColor: mgoColors.c2d,
                textFontSize: '1rem',
                textMobileFontSize: '1rem',
                textFontFamily: 'sans-serif',
                textBackground: mgoColors.c1,
                textPadding: 'none',
                textMobilePadding: 'none',
            },
        },

        appCheckBoxSimple: {
            textFontColor: mgoColors.c1,
            BackgroundColor: mgoColors.c2,
            textFontSize: '1.5rem',
            padding: '0 0 4px 0px',
            width: '24px',
            height: '32px',
        },

        appTextSelectable: {
            textFontColor: 'black',
            textFontSize: '1rem',
            textFontFamily: 'sans-serif',
            textBackground: 'unset',
            textPadding: 'none',

            textSelectedFontColor: 'black',
            textSelectedFontSize: '1rem',
            textSelectedFontFamily: 'sans-serif',
            textSelectedBackground: '#eeeeee',
            textSelectedPadding: 'none',
            textSelectedBorder: '1px #aaa solid',
            textSelectedBorderRadius: '8px',
        },

        theAppDropdownForAPIs: {
            dropdownButtonTextColor: mgoColors.c1,
            dropdownButtonTextFontSize: "1rem",
            dropdownButtonMobileTextFontSize: "0.8rem",
            dropdownButtonTextFontFamily: "sans-serif",
            dropdownHoverButtonBackground: mgoColors.c2dh,
            dropdownButtonBackground: mgoColors.c2d,
            dropdownButtonPadding: "2px 8px 2px 8px",

            dropdownIconName: "doubleArrowDown-white.png",
            dropdownIconHeight: 12,
            dropdownIconPadding: "0 0px 0 1px",

            dropdownWidth: "25rem",
            dropdownContainerPadding: "2px",
            dropdownContainerBackgroundColor: mgoColors.c2,
            dropdownContainerBorder: 'none',

            dropdownContentHeight: "auto",
            dropdownContentBackgroundColor: mgoColors.c1,
            dropdownContentHoverColor: mgoColors.c1hh,
            dropdownContentDisabledBackgroundColor: mgoColors.c1d,
            dropdownContentDisabledHoverColor: mgoColors.c1dh,
            dropdownContentPadding: "2px 0 2px 8px",
            dropdownContentFontSize: "1rem",
            dropdownContentFontColor: mgoColors.c2,
            dropdownContentDisabledFontColor: mgoColors.c2,
            dropdownContentFontFamily: "sans-serif",
            dropdownContentShiftDown: "1.5rem",
            dropdownContentShiftRight: "0",

            dropdownButtonBorder: "1px " + mgoColors.c2 + " solid",
            dropdownButtonBorderRadius: "8px",
            dropdownButtonBoxShadow: "1px 2px 6px " + mgoColors.c2,
        },

        theNavbar: {
            navbarHeight: 64,
            navbarLogo: "NFLogo.png",
            navbarLogoHeight: 64,
            navbarLogoPadding: "14px 0 0 8px",
            navbarDropdownIconName: "doubleArrowDown-white.png",
            navbarDropdownIconHeight: 20,
            navbarDropdownButtonPaddingFromLeft: "24px",
            navbarDropdownButtonPadding: '10px 10px 10px 10px',
            navbarFontFamilyLeftLinks: "sans-serif",
            navbarFontFamilyDropdownLinks: "sans-serif",
            navbarDropdownButtonBackgroundColor: mgoColors.c2,
            navbarBackgroundColor: mgoColors.c4,
            navbarBorderBottom: "gray 2px solid",
            navbarLeftLinksFontSize: "1.1rem",
            navbarLeftLinksFontColor: mgoColors.c1,
            navbarLeftLinksHoverColor: mgoColors.c1h,
            navbarDropdownContentHeight: "auto",
            navbarDropdownContentHeightCollapse: "auto",
            navbarDropdownWidth: "220px",
            navbarDropdownContentFontSize: "1.25rem",
            navbarDropdownContentFontColor: mgoColors.c2,
            navbarDropdownContentHoverColor: mgoColors.c1h,
            navbarDropdownContentBackgroundColor: mgoColors.c1,
            navbarDropdownContainerPadding: "none",
            navbarDropdownContainerBorder: "1px " + mgoColors.c3 + " solid",
            navbarDropdownContentPadding: "10px 5px 10px 15px",
            navbarDropdownContentShiftDown: "45px",
            navbarDropdownContentShiftRight: "-160px"
        },

        HelpPageTitle: {
            padding: '8px 0 8px 16px',
            fontColor: mgoColors.c1,
            fontFamily: 'sans-serif',
            fontSize: '24px',
            background: mgoColors.c2d,
            width: '98%',
        },

        SectionBlockPanel: {
            height: '30px',
            spacingBeforeTitle: '8px',
            titleFontColor: mgoColors.c1,
            titleBackgroundColor: mgoColors.c3,
            titleFontSize: '1rem',
            titleFontFamily: 'sans-serif',
            titlePadding: '0 8px 0 0',
            titleMobilePadding: '0 0 0px 0',
            titleFontWeight: "800",
            titleMobileFontWeight: "600",
        },

        ContentItem: {
            backgroundColor: 'inherited',

            linkFontColor: mgoColors.c1bl,
            linkFontSize: '14px',
            linkTextDecoration: 'underline',

            paddingContent: '4px 16px 16px 0',
            contentColor: mgoColors.c1,

            showHorizontalSeparator: true,
            horizontalSeparatorWidth: '50%',
            horizontalSeparatorHeight: '10px',
            horizontalSeparatorAlignment: 'left',
            horizontalSeparatorColor: mgoColors.c1d,
            horizontalSeparatorThickness: '2px',
        },

        ContentPageLinks: {
            items: [
                {
                    name: "Courses", label: "Courses",
                    linkLabel: "Courses Page",
                    linkDescription: "This describes the landing page when you first login in"
                },
                {
                    name: "Course", label: "Course",
                    linkLabel: "Course Page",
                    linkDescription:
                        "This is where a specific course is viewed, edited and/or deleted. You can also\n" +
                        "create new courses and offerings based on the currently viewed course."
                },
                {
                    name: "Offering", label: "Offering",
                    linkLabel: "Offering Page",
                    linkDescription:
                        "This is where a specific offering for a course is viewed and edited. This " +
                        "includes adding enrollment to the offering.  You can also " +
                        "create new offerings based on the currently viewed course and create new assessments for " +
                        "the offering. You can also delete assessments previously assigned to the offering. The " +
                        "Learning Outcomes (LOs) viewed or edited here will be the ones associated with the offering " +
                        "and can be distinct from the ones associated with the underlying course."
                },
                {
                    name: "Assessment", label: "Assessment",
                    linkLabel: "Assessment Page",
                    linkDescription:
                        "This is where a specific assessment for a specific offering is viewed and edited. You can \n" +
                        "add new questions associate each question with one or more Learning Outcomes (LOs)."
                },
                {
                    name: "FinalGrades", label: "Final Grades",
                    linkLabel: "Final Grades Page",
                    linkDescription:
                        "This is where the Final Grades are viewed. You can also see the percent of the total " +
                        "grade associated with each Learning Outcome. Other data such as Learning Outcome grades " +
                        "for each student are also found here."
                },
                {
                    name: "Students", label: "Students",
                    linkLabel: "Students Page",
                    linkDescription:
                        "This is where new students are viewed, edited and/or deleted. Students must be added here " +
                        "in order to be enrolled in any offering.  Students edited or deleted here will be " +
                        "edited or deleted from " +
                        "every assessment of every offering of every course."
                },
                {
                    name: "Archiving", label: "Archiving",
                    linkLabel: "Archiving and Backups Page",
                    linkDescription:
                        "This is where offerings are backed up, loaded from backups or downloaded as Excel files."
                },
            ]
        },

        HomePage: {
            mainTitle: "Help Home Page",
            contentsTitle: "Contents",
        },

        ContactUs: {
            mainTitle: "Contact Us",
            preMessageText: "Note: Contact us from Help page",

            paddingAroundContactUs: '0 0 0 16px',

            overallWidthInPx: 600,
            overallBackground: mgoColors.c3c,
            overallBackgroundBlink: mgoColors.c3d,
            overallPadding: '4px 8px 4px 8px',
            overallBorderRadius: '16px',
            overallBorder: mgoColors.c3 + ' 2px solid',
            titleFontColor: mgoColors.c2d,
            titleFontSize: '24px',
            titlePadding: '0 0 0 0',
            subtitleFontColor: mgoColors.c1,
            subtitleFontSize: '16px',
            subtitlePadding: '0 0 16px 0',
            borderInput: mgoColors.c3 + ' 2px solid',
            borderInputRadius: '8px',
            inputPadding: '0 0 8px 0',
            submitFontColor: mgoColors.c1,
            submitBackgroundColor: mgoColors.c2,
            submitHoverBackgroundColor: mgoColors.c2hb,
            submitShadow: "2px 2px 4px " + mgoColors.c2,
            submitPadding: '8px 0 8px 0',
            borderSubmit: mgoColors.c3 + ' 1px solid',
            borderSubmitRadius: '8px',
            noteFontSize: '14px',
            noteFontColor: mgoColors.c2d,
            noteFontFamily: 'serif',
            noteHeight: '60px',
            noteMinHeight: '30px',
        },

        CoursesPage: {
            mainTitleText: 'Courses Page',

            parts: [
                {
                    startHidden: true,
                    sectionTitle: 'Overview of Courses Page',
                    sectionImage: 'Courses Page - fig 1.png',
                    sectionTextArray:
                        [
                            "You land on this page when you login. From this page, you can navigate to any course and\n" +
                            "any offering you've previously created.  You can also go to the 'final grades' page of\n" +
                            "any offering.  Finally, you can also create a new course which you need to do if you\n" +
                            "have a new account. To create a new course, simply click on the button 'New course' in\n" +
                            "the control panel.  You can only create a new offering while either viewing a course\n" +
                            "or while viewing an offering. You can navigate to those pages from this one.\n"
                        ],
                },
                {
                    startHidden: true,
                    sectionTitle: 'Selecting and Navigating to a Course',
                    sectionVideo: 'Courses movie - 1b.mp4',
                    sectionTextArray:
                        [
                            "You can only select a course or offering if you've previously created them. " +
                            "You select a course by clicking on the course identification which consists of the " +
                            "course code and number in the main table. When you click " +
                            "on a course identification, the text is highlighted and the 'Go to course' button in " +
                            "the control panel is enabled. Clicking on the same course again, deselects that course. " +
                            "If you click on course, any selected course will be de-selected and the the clicked course will " +
                            "then be selected.  Once selected, clicking on 'Go to course' button in the control panel takes you " +
                            "to that course."
                        ],
                },
                {
                    startHidden: true,
                    sectionTitle: 'Selecting and Navigating to an Offering',
                    sectionVideo: 'Courses movie - 2b.mp4',
                    sectionTextArray:
                        ["To select an offering, you must first click on the offering summary in the second " +
                        "column of the main table.  This opens up to show all the available offerings for that course. " +
                        "You then select an offering by clicking on the offering identification which consists of the " +
                        "year, period and section of the offering. When you click on an offering identification, the text " +
                        "is highlighted and the 'Go to offering' and 'Go to final grades' are both enabled. Clicking on " +
                        "the same offering again, deselects that offering.  If you click on another offering, for that " +
                        "course or another course, any selected offering will be de-selected and the the clicked offering " +
                        "will be selected. You can only select one offering at a time.  Once selected, clicking on the " +
                        "'Go to offering' in the control panel button takes you to that offering.  Alternatively, clicking on the " +
                        "'Go to final grades' button in the control panel takes you to the final grades page for that offering. "
                        ],
                }
            ],
        },

        CoursePage: {
            mainTitleText: 'Course Page',

            parts: [
                {
                    startHidden: true,
                    sectionTitle: 'Overview of Course Page',
                    sectionImage: 'Course Page - fig 1.png',
                    sectionTextArray:
                        [
                            "This is where a specific course is viewed, edited and/or deleted. In this page,\n" +
                            "you can edit basic course information, namely, the course code, course number, title,\n" +
                            "description and number of credits. You can also view and edit course Learning Outcomes,\n" +
                            "List of Topics, Pre-Requisites, Co-Requisites, Required Texts, Recommended Texts\n" +
                            "and Other Notes.\n",
                            "In addition, you can select, delete or create new offerings.  All offerings are shown\n" +
                            "in a table which can viewed or hidden by a check box in the tools panel.\n" +
                            "A new offering created in a course page will have its basic data copied from its course\n" +
                            "template.  An offering can subsequently diverge in some or all of its data from the\n" +
                            "course that spawned it and the course will retain its original values unless\n" +
                            "it is itself edited.\n",
                            "On this page, you can also switch to view other courses through the dropdown menu\n" +
                            "available in the tools panel.  If the offerings table is not hidden, you will be able to\n" +
                            "see all the offerings available for each course as you switch to that course.\n",
                            "Finally, on this page you can delete either an offering of that course or the course \n" +
                            "itself. By selecting an offering, you can then delete that offering.  This will also\n" +
                            "delete all offerings associated with that course along with all the assessments of those\n" +
                            "offerings. A warning will appear when you click to delete allowing you to cancel your\n" +
                            "request.  To delete a course, you simply click the 'Delete course' button on the \n" +
                            "control panel.  This will also delete all offerings for that course. A warning will appear\n" +
                            "when you click to delete allowing you to cancel your request for deleting the course."
                        ],
                },
                {
                    startHidden: true,
                    sectionTitle: 'Edit basic course data',
                    sectionVideo: 'Course-single movie - 1b.mp4',
                    sectionTextArray:
                        [
                            "To edit basic course data, you first click on the 'show' button of the section 'Basic Course Information' on the\n" +
                            "tool panel but only if the component is hidden\n" +
                            "To edit the data, you then click on the 'edit' button on the component's tool panel\n" +
                            "This allows editing of the fields in this component and is indicated by the change of color of those fields\n" +
                            "You then simply edit the data in the usual way\n" +
                            "The 'Course code', 'Course number' and 'Title' fields may not be empty and the save button will be disabled if any\n" +
                            "of them are empty\n" +
                            "The 'Number of Credits' field may be empty but if it is not empty then only integers greater or equal to zero are\n" +
                            "allowed in that field\n" +
                            "A horizontal green bar under the components tool panel indicates that the data needs saving\n" +
                            "You save by clicking on the save button\n" +
                            "The changes are immediately used where applicable such as in the title of the page\n"
                        ],
                },
                {
                    startHidden: true,
                    sectionTitle: 'Edit Learning Outcomes (similar for all lists below it)',
                    sectionVideo: 'Course-single movie - 2b.mp4',
                    sectionTextArray:
                        [
                            " To edit Learning Outcomes, you first click on the 'show' button of that section on its tool panel\n" +
                            "but only if the component is hidden\n" +
                            " To edit existing data, you click on the edit checkbox corresponding to the item you want to edit\n" +
                            " This enters the edit mode for that item and you can then edit that field\n" +
                            " To add new Learning Outcomes, you click on the 'edit list' on the component's tool panel then\n" +
                            "click on the 'Add' button that appears\n" +
                            " Next go to the new item that appears and click on its edit button and write that Learning Outcome.\n" +
                            " A Learning Outcome item may not be left empty and the 'save' button will be disabled until some data is entered.\n" +
                            " You cannot delete a new Learning Outcome item until you have saved it\n" +
                            " After editing a Learning Outcome, the item will be surrounded by a green box\n" +
                            " Clicking on save, saves the learning outcome\n" +
                            " To delete a Learning Outcome, click the 'edit list' again\n" +
                            " Boxes will appear next to each of the learning outcomes\n" +
                            " Click one or more boxes to select those items for deletion.\n" +
                            " Note that their color changes to red\n" +
                            " Click any selected box again to deselect it\n" +
                            " Click the 'delete' button to delete all selected items\n" +
                            " Click the 'save' button to save the changes\n"
                        ],
                },
                {
                    startHidden: true,
                    sectionTitle: 'Navigating to or deleting a course offering',
                    sectionVideo: 'Course-single movie - 3b.mp4',
                    sectionTextArray:
                        [
                            " Click the 'show offering' check box in the main tools panel if it is not already selected to show the table for\n" +
                            "the course's offering\n" +
                            " You can change courses by selecting from the dropdown button in the main tools panel\n" +
                            " Click on the 'Year' of any Offering to select that offering\n" +
                            " The 'Year' changes color and the 'Go to offering' button is enabled\n" +
                            " When an offering is selected, you can click 'Delete offering' in the main tools panel to delete that offering\n" +
                            " This will open a modal dialog to confirm your request\n" +
                            " Deleting an offering deletes all the assessments and grades associated with that offering\n" +
                            " Also, when an offering is selected, you can click on the 'Go to offering' to go to that offering\n"
                        ],
                }
            ],
        },

        OfferingPage: {
            mainTitleText: 'Offering Page',

            parts: [
                {
                    startHidden: true,
                    sectionTitle: 'Overview of Offering Page',
                    sectionImage: 'Offering Page - fig 1.png',
                    sectionTextArray:
                        [
                            "This is where a specific offering is viewed and edited.  An offering is a specific course\n" +
                            "being given at a specific time and for a specific set of students. In this page,\n" +
                            "you can edit basic offering information, namely, the year the offering is given, the period,\n" +
                            "the section, the instructor first and last name, the department, the institution, a\n" +
                            "description and the number of credits. Both the last two can be different from the ones for\n" +
                            "the parent course and will not modify the parent course's data.\n" +
                            "You can also view and edit course Learning Outcomes, List of Topics, Pre-Requisites, Co-Requisites,\n" +
                            "Required Texts, Recommended Texts and Other Notes. All of those lists can also be different from\n" +
                            "the ones for the parent course and will not modify the parent course's data\n",
                            "In addition, you can enroll students to the offering and un-enroll students.  Students can only\n" +
                            "be enrolled if they have first been added to your account in the 'Students Page'. Students who are\n" +
                            "un-enrolled return to the pool of students that can be re-enrolled in this course or enrolled in another.\n",
                            "On this page, you can also create new assessments and edit their names.  Once a set of assessments is\n" +
                            "created, you can add one or more set of weights which are used in calculating the final grades.  The final\n" +
                            "grade for each student will be calculated based on each set of weights and the highest final grade candidate will be\n" +
                            "a student's final grade on a per student basis.  The set of weights are also used in calculating Learning Outcome grades. \n" +
                            "A missing weight value implies the weight has a default zero relative weight value.\n",
                            "In addition to adding and editing sets of weights, you can delete sets of weights.  However, you need at least \n" +
                            "one set of weights in order to calculate final grades.  The weights may for convenience add up to 100 but they are not\n" +
                            "required to do so.  The weights are relative factors and the application automatically calculates the\n" +
                            "implied percentages. When a user clicks to delete a weight, it is immediately deleted without further confirmation. \n",
                            "Assessments that are created can also be deleted.  However, this will delete all the grades associated with that\n" +
                            "assessment. A warning will appear when you click to delete an assessment allowing you to cancel your request.\n",
                            "On this page, you can also select any assessment in order to navigate to that assessment. \n" +
                            "You can also navigate to the the final grades or to the course associated with the offering at any time.\n",
                            "Finally, you can also create a new offering on this page.  When this is done, this page will create and load data\n" +
                            "associated with the new offering . The data for the old offering are used as a default for the data created\n" +
                            "for the new offering but the new offering will have its data independent so that editing the data for the\n" +
                            "new or old offering will not affect each other.\n"
                        ],
                },
                {
                    startHidden: true,
                    sectionTitle: 'Edit basic offering data',
                    sectionVideo: 'Offering movie - 1b.mp4',
                    sectionTextArray:
                        [
                            "To edit basic offering data, you first click on the 'show' button of the section 'Basic Offering Information' on the\n" +
                            "tool panel but only if the component is hidden\n" +
                            "To edit the data, you then click on the 'edit' button on the component's tool panel\n" +
                            "This allows editing of the fields in this component and is indicated by the change of color of those fields\n" +
                            "You then simply edit the data in the usual way\n" +
                            "The 'Year', 'Period' and 'Section' fields may not be empty and the save button will be disabled if any\n" +
                            "of them are empty.\n" +
                            "The 'Year' field must be a number between than 1900 and 2100\n" +
                            "The 'Number of Credits' field may be empty but if it is not empty then only integers greater or equal to zero are\n" +
                            "allowed in that field\n" +
                            "A horizontal green bar under the components tool panel indicates that the data needs saving\n" +
                            "You save by clicking on the save button\n" +
                            "The changes are immediately used where applicable such as in the title of the page\n"
                        ],
                },
                {
                    startHidden: true,
                    sectionTitle: 'Edit Enrollment',
                    sectionVideo: 'Offering movie - 2b.mp4',
                    sectionTextArray:
                        [
                            "To edit 'Enrollment', you first click on the 'show' button of the section 'Enrollment' on the\n" +
                            "tool panel but only if the component is hidden\n" +
                            "Now click on the 'edit list' on the component's tool panel\n" +
                            "This will show two new buttons on the component's tool panel which are the 'Remove students' and 'Add students' buttons\n" +
                            "This will also show a table of Available students below the table of enrolled students\n" +
                            "To enroll students, go to the table of available students and click on one or more boxes to the left of each\n" +
                            "available student.\n" +
                            "If you click once on a box, the student is selected and the row turns green.\n" +
                            "Clicking twice on the same box deselects the student\n" +
                            "If you have one or more selected students, the 'Add students' button will be enabled.\n" +
                            "To enroll the selected students, click the 'Add students' button\n" +
                            "To un-enroll students, go to the table of enrolled students and click on one or more boxes to the left of each\n" +
                            "enrolled student.\n" +
                            "If you click once on a box, the student is selected and the row turns red.\n" +
                            "Clicking twice on the same box deselects the enrolled student\n" +
                            "If you have one or more selected students, the 'Remove students' button will be enabled.\n" +
                            "To un-enroll the selected students, click the 'Remove students' button\n" +
                            "Note that enrolling students removes them from the pool of available students for this offering while\n" +
                            "un-enrolling students adds them to the pool of available students for this offering\n" +
                            "The enrolled or available students are not affected in any other offering"
                        ],
                },
                {
                    startHidden: true,
                    sectionTitle: 'How to go to an assessment',
                    sectionVideo: 'Offering movie - 3b.mp4',
                    sectionTextArray:
                        [
                            "Click on the 'show' button of the section 'Assessments' on the tool panel but only if that component is hidden\n" +
                            "Click on the name of any assessment\n" +
                            "That assessment is selected and its color changes\n" +
                            "When you select an assessment, the 'Go to assessment' button is enabled\n" +
                            "Clicking on the same assessment name de-selects the assessment\n" +
                            "Clicking on another assessment name selects that assessment and de-selects any previously selected assessment\n" +
                            "When enabled, click on the 'Go to assessment' button to go to that assessment"
                        ],
                },
                {
                    startHidden: true,
                    sectionTitle: 'Edit Assessments block (part 1: Assessments)',
                    sectionVideo: 'Offering movie - 4b.mp4',
                    sectionTextArray:
                        [
                            "Click on the 'show' button of the section 'Assessments' on the tool panel but only if that component is hidden\n" +
                            "To edit an existing assessment's name, click on the checkbox next to the name to go into edit mode for that name.\n" +
                            "Edit the name in the usual way\n" +
                            "Click save on the tools panel of that component or 'save offering' on the main control panel to save the new name\n" +
                            "To add or delete an assessment, click on 'edit list' on the tool panel of the assessment component\n" +
                            "When 'edit list' is selected, 3 additional buttons show on the 'assessments' tool panel\n" +
                            "To add an assessment, click on the 'Add Assessment' button\n" +
                            "A new assessment appears with the name the symbol 'question mark'\n" +
                            "Edit that name to whatever is convenient\n" +
                            "Click save on the component's control panel to save the assessment\n" +
                            "If you don't edit the symbol 'question mark' for the new assessment, the new assessment is still created\n" +
                            "but its name will be assigned automatically and generically\n" +
                            "To delete an assessment, click on the box in the first column of its row when 'edit list' is selected.\n" +
                            "The row will turn red\n" +
                            "Clicking again consecutively on the same box will deselect it\n" +
                            "Clicking on another box will select that assessment and deselect any currently selected one\n" +
                            "You can only delete one assessment at a time\n" +
                            "Having selected an assessment to be deleted with its row shown in red, click the delete button\n" +
                            "This will open a modal dialog to confirm your request\n" +
                            "Confirm to delete the assessment"
                        ],
                },
                {
                    startHidden: true,
                    sectionTitle: 'Edit Assessments block (part 2: Weights)',
                    sectionVideo: 'Offering movie - 5b.mp4',
                    sectionTextArray:
                        [
                            "Click on the 'show' button of the section 'Assessments' on the tool panel but only if that component is hidden\n" +
                            "To edit an existing weights set of values, click on the checkbox next to the header of the column of 'Weights' that\n" +
                            "you want to edit to go into edit mode\n" +
                            "Edit the weights in the columns in the usual way\n" +
                            "Only integer values greater or equal to zero are allowed as weights\n" +
                            "You can also leave a cell empty and a default value of zero is then implied\n" +
                            "When there are any changes in any weight value and all input is valid in the component, the save button on\n" +
                            "that component is enabled.\n" +
                            "Click save to save the weight values\n" +
                            "To add or delete an assessment, click on 'edit list' on the tool panel of the assessment component if it is\n" +
                            "not already selected\n" +
                            "When 'edit list' is selected, 3 additional buttons show on the 'assessments' tool panel\n" +
                            "To add a weight set column, click on the 'Add Weights' button\n" +
                            "A new column appears with the title 'Weights' and all entries empty\n" +
                            "Edit the weights as convenient\n" +
                            "Click save on the component's control panel to save the new values\n" +
                            "If you don't edit the weights, the new weights are saved with implied weights all zero\n" +
                            "To delete a column of weights, click on the word 'Weights' for that column\n" +
                            "The column will turn red\n" +
                            "Clicking again consecutively on the same title will deselect it\n" +
                            "Clicking on another title will select that column and deselect any currently selected one\n" +
                            "You can only delete one weights column at a time\n" +
                            "Having selected a weights column to be deleted with its column shown in red, click the delete button\n" +
                            "The column is immediately deleted but a modal may appear if you have also selected an assessment to be deleted"
                        ],
                },
                {
                    startHidden: true,
                    sectionTitle: 'Edit Learning Outcomes (similar for all lists below it)',
                    sectionVideo: 'Offering movie - 6b.mp4',
                    sectionTextArray:
                        [
                            "To edit Learning Outcomes, you first click on the 'show' button of that section on its tool panel\n" +
                            "but only if the component is hidden.\n" +
                            "To edit existing data, you click on the edit checkbox corresponding to the item you want to edit.\n" +
                            "This enters the edit mode for that item and you can then edit that field.\n" +
                            "You must click the save button for the changes to be saved.\n" +
                            "To add new Learning Outcomes, you click on the 'edit list' on the component's tool panel then\n" +
                            "click on the 'Add' button that appears.\n" +
                            "Next go to the new item that appears and click on its edit button and write that Learning Outcome.\n" +
                            "A Learning Outcome item may not be left empty and the 'save' button will be disabled until some data is\n" +
                            "entered.\n" +
                            "You cannot delete a new Learning Outcome item until you have saved it.\n" +
                            "After editing a Learning Outcome, the item will be surrounded by a green box.\n" +
                            "Clicking on save, saves the learning outcome.\n" +
                            "To delete a Learning Outcome, click the 'edit list' again.\n" +
                            "Boxes will appear next to each of the learning outcomes.\n" +
                            "Click one or more boxes to select those items for deletion.\n" +
                            "Note that their color changes to red.\n" +
                            "Click any selected box again to deselect it.\n" +
                            "Click the 'delete' button to delete all selected items.\n" +
                            "Click the 'save' button to save the changes.\n" +
                            "Let's quickly look at the Learning Outcomes of the parent course.\n" +
                            "Click on 'Go to course' in the main tools panel.\n" +
                            "Click on 'show' to show the Learning Outcomes.\n" +
                            "Notice that the Learning Outcomes are different than those of the offering.\n" +
                            "Select the offering we were at and click on the 'go to offering' in the main tools panel.\n" +
                            "Click on 'show' for the 'Learning Outcomes' component.\n" +
                            "Click on the 'edit list' button and we see 3 buttons appear.\n" +
                            "Click on 'update course El Ohs'.\n" +
                            "Now let's go back to the 'Learning Outcomes' for the course as we did before.\n" +
                            "We find that the course 'Learning Outcomes' have been updated.\n" +
                            "Note that other offerings for the same course stay as they are."
                        ],
                }
            ],
        },

        AssessmentPage: {
            mainTitleText: 'Assessment Page',

            parts: [
                {
                    startHidden: true,
                    sectionTitle: 'Overview of Assessment Page',
                    sectionImage: 'Assessment Page - fig 1.png',
                    sectionTextArray:
                        [
                            "This is where a specific assessment is viewed and edited. An assessment allows assigning and editing a set of\n" +
                            "points for each enrolled student which then contribute to the final grades.\n" +
                            "An assessment can be a homework assignment, a quiz, an exam, a project, a class attendance\n" +
                            "grade or even some bonus points awarded for whatever reason. An assessment may contribute to the grades\n" +
                            "associated with zero or more Learning\n" +
                            "Outcomes and the specification of this contribution is done in this assessment page.\n",
                            "There are 3 components that the user can edit in the assessment page:\n",
                            "1. 'Question Points' component:  Here, a user can add or remove a 'question' and can assign points to each 'question'.\n" +
                            "A question simply creates a number of points that can be assigned to each student on an assessment. The number of\n" +
                            "points must be greater than zero but can be an integer or decimal number. The total number of points on all\n" +
                            "questions do not have to add to 100.  The app will calculate a total 'raw' grade, a total 'adjusted' grade and\n" +
                            "a 'percent' grade based on the adjusted grade.  The 'percent' grade is simply the percent points that a student\n" +
                            "gets relative to the total points assigned to all questions. The 'adjusted' total grade is the 'raw' grade with\n" +
                            "'bonus' and 'other' points added to the grade on a per student basis.\n",
                            "'Question Points' can be edited and can be deleted.  When a 'question' is deleted, all the grades associated\n" +
                            "with that question are also deleted and the total and percent grades are automatically re-adjusted.  In addition,\n" +
                            "the 'questions' are renamed so that they always are numbered consecutively.\n",
                            "2. 'Students Versus Assessment Grades': Here, a user can assign grades on each question to each student and can also\n" +
                            "assign 'curve' and 'other' points. Users can also sort the data shown by clicking at the small triangles on the headers\n" +
                            "which also allows reversing the order by clicking more than once consecutively on the same header.\n",
                            "3. 'Learning Outcomes Versus Question': Here, a user can assign relative waits to zero or more questions on\n" +
                            "zero or more Learning Outcomes.  It is recommended that each question is related to only one Learning Outcome but\n" +
                            "if that is not possible then the number assigned to each Learning Outcome for a given question is a relative weight\n" +
                            "used to calculate Learning Outcome grades for each student.  An empty cell means the weight is zero in that cell.\n",
                            "In addition to components that the user can edit, the assessment page also shows statistical summaries of the\n" +
                            "student grades for the chosen assessment, statistical summaries of the Learning Outcome grades and Learning Outcome\n" +
                            "grades for each student and each Learning Outcome.\n",
                            "From this page, a user can navigate to the course, offering or final grades associated with the assessment \n" +
                            "associated with this page."
                        ],
                },
                {
                    startHidden: true,
                    sectionTitle: 'Editing, adding and deleting questions',
                    sectionVideo: 'Assessment movie - 1b.mp4',
                    sectionTextArray:
                        [
                            "To edit the number of questions and points on them, you first click on the 'show' button of the section titled\n" +
                            "'Question Points' on its tool panel but only if the component is hidden.\n" +
                            "To edit the maximum points on a given question, click on the checkbox left of the header in the associated header.\n" +
                            "This enters the edit mode for that item and you can then edit the Points field.\n" +
                            "As you change the number of points, The 'Percent' points gets automatically updated.\n" +
                            "The number of points cam be any integer or decimal positive number.\n" +
                            "If the field is left empty then a value of zero is assumed but this will not allow you to enter any grades.\n" +
                            "A nonzero positive number is required.\n" +
                            "After editing one or more points, those items will be surrounded by a green box and a green line will be\n" +
                            "shown under the control panel.\n" +
                            "Clicking on save on the component's control panel, saves the data.\n" +
                            "To add or delete new questions, first click on 'edit list' on the component's control panel.\n" +
                            "Two buttons appear, a 'Delete Question' button and an 'Add Question' button.\n" +
                            "To add a question, click on the 'Add Question' button.\n" +
                            "A new column is added.\n" +
                            "Edit that column as convenient and save.\n" +
                            "To delete a question, the 'edit list' checkbox must be selected.\n" +
                            "Click on any question header such as 'Q 1', 'Q 2' and so on.\n" +
                            "The column will turn red as well as other affected columns in other components if they are hidden.\n" +
                            "Clicking again on the same question header deselects that item.\n" +
                            "Clicking on another question header selects that column and deselects the currently selected one if any.\n" +
                            "With a column selected, click on 'Delete Question' to delete that column."
                        ],
                },
                {
                    startHidden: true,
                    sectionTitle: 'Editing grades',
                    sectionVideo: 'Assessment movie - 2b.mp4',
                    sectionTextArray:
                        [
                            "To edit student grades, you first click on the 'show' button of the section titled\n" +
                            "'Students Versus Assessment Grades' on its tool panel but only if the component is hidden.\n" +
                            "To edit the points on any given question, click on the checkbox left of the header in the associated header\n" +
                            "for that question.\n" +
                            "This enters the edit mode for all grades of all the students in that column.\n" +
                            "You can edit multiple columns at the same time.\n" +
                            "Edit grades or enter new ones as convenient.\n" +
                            "Grades must be between zero and the maximum grades allocated for each corresponding question.\n" +
                            "After editing or entering one or more grades, those items will be surrounded by a green box and a green line will be\n" +
                            "shown under the control panel.\n" +
                            "Clicking on save on the component's control panel, saves the data.\n" +
                            "Be careful, if you've clicked to sort on the GA, GR or other column with varying values, the item you're sorting\n" +
                            "may shift while you're editing.  It's better to sort on a non-varying column such as code, first name or last name\n" +
                            "when editing grades."
                        ],
                },
                {
                    startHidden: true,
                    sectionTitle: 'Editing Learning Outcomes Versus Question',
                    sectionVideo: 'Assessment movie - 3c.mp4',
                    sectionTextArray:
                        ["To edit Learning Outcomes, you first click on the 'show' button of that component on its tool panel\n" +
                        "but only if the component is hidden.\n" +
                        "To edit existing data, you click on the edit checkbox corresponding to the item you want to edit.\n" +
                        "This enters the edit mode for that item and you can then edit that field.\n" +
                        "To add new Learning Outcomes, you click on the 'edit list' on the component's tool panel then\n" +
                        "click on the 'Add' button that appears.\n" +
                        "Next go to the new item that appears and click on its edit button and write that Learning Outcome.\n" +
                        "A Learning Outcome item may not be left empty and the 'save' button will be disabled until some data is entered.\n" +
                        "You cannot delete a new Learning Outcome item until you have saved it.\n" +
                        "After editing a Learning Outcome, the item will be surrounded by a green box.\n" +
                        "Clicking on save, saves the learning outcome.\n" +
                        "To delete a Learning Outcome, click the 'edit list' again.\n" +
                        "Boxes will appear next to each of the learning outcomes.\n" +
                        "Click one or more boxes to select those items for deletion.\n" +
                        "Note that their color changes to red.\n" +
                        "Click any selected box again to deselect it.\n" +
                        "Click the 'delete' button to delete all selected items.\n" +
                        "Click the 'save' button to save the changes.\n" +
                        "Let's quickly look at the Learning Outcomes of the parent course.\n" +
                        "Click on 'Go to course' in the main tools panel.\n" +
                        "Click on 'show' to show the Learning Outcomes.\n" +
                        "Notice that the Learning Outcomes are different than those of the offering.\n" +
                        "Select the offering we were at and click on the 'go to offering' in the main tools panel.\n" +
                        "Click on 'show' for the 'Learning Outcomes' component.\n" +
                        "Click on the 'edit list' button and we see 3 buttons appear.\n" +
                        "Click on 'update course LOs'.\n" +
                        "Now let's go back to the 'Learning Outcomes' for the course as we did before.\n" +
                        "We find that the course 'Learning Outcomes' have been updated.\n" +
                        "Note that other offerings for the same course stay as they are.\n"],
                }
            ],
        },

        FinalGradesPage: {
            mainTitleText: 'Final Grades Page',

            parts: [
                {
                    startHidden: true,
                    sectionTitle: 'Overview of Final Grades Page',
                    sectionImage: 'FinalGrades Page - fig 1.png',
                    sectionTextArray:
                        [
                            "This is where you find final grades, final grades summaries, Final Learning Outcome grades for each Learning Outcome,\n" +
                            "Final Learning Outcome grades summaries and a section that summarizes what percent of the total grade is\n" +
                            "due to each Learning Outcome.  Note that all the data associated with Learning Outcomes is only available if\n" +
                            "Learning Outcomes have been defined and at least one assessment has associated questions to Learning Outcomes.\n",
                            "In many of the tables presented here, headers have small triangles that when clicked one or more times\n" +
                            "consecutively changes how the data is sorted.\n",
                            "Finally, in the 'Assessments Grades Summaries', the names of the assessments can be selected by clicking on them\n" +
                            "and once selected, a user can navigate to that assessment by clicking on the 'Go to assessment' button on the\n" +
                            "control panel.\n",
                            "In addition, the user can go to the associated course or to the associated offering at any time by clicking on the\n" +
                            "'Go to course' or 'Go to offering' buttons respectively that are located on the control panel."
                        ],
                },
                {
                    startHidden: true,
                    sectionTitle: 'Editing Final Grades Data',
                    sectionVideo: 'Final-Grades movie - 1b.mp4',
                    sectionTextArray:
                        [
                            "Click on the 'show' button of the section 'Assessments Grades Summaries' on the tool panel but only if that\n" +
                            "component is hidden.\n" +
                            "Click on the name of any assessment.\n" +
                            "That assessment is selected and its color changes.\n" +
                            "When you select an assessment, the 'Go to assessment' button is enabled.\n" +
                            "Clicking on the same assessment name de-selects the assessment.\n" +
                            "Clicking on another assessment name selects that assessment and de-selects any previously selected assessment.\n" +
                            "When enabled, click on the 'Go to assessment' button to go to that assessment."
                        ],
                },
                {
                    startHidden: true,
                    sectionTitle: 'Editing Final Grades Data',
                    sectionVideo: 'Final-Grades movie - 2b.mp4',
                    sectionTextArray:
                        [
                            "To edit assessment weights, you first click on the 'show' button of that component on its tool panel but only\n" +
                            "if the component is hidden.\n" +
                            "To dynamically see the effect of changing the weights, click show on any other component.  Here we will look at\n" +
                            "the 'Students Versus Assessment Grades'.\n" +
                            "To edit an existing weights set of values, click on the checkbox next to the header of the column of 'Weights' that\n" +
                            "you want to edit to go into edit mode.\n" +
                            "If all the weights are empty or zero then the 'Grade Adjusted Percent' shown as 'GA%' will all be zero.\n" +
                            "Edit the weights in the columns in the usual way.\n" +
                            "Notice that as you edit the weights, the values in GA% may change.\n" +
                            "Only integer values greater or equal to zero are allowed as weights.\n" +
                            "You can also leave a cell empty and a default value of zero is then implied.\n" +
                            "When there are any changes in any weight value AND all input is valid in the component, the save button on\n" +
                            "that component is enabled.\n" +
                            "Click save to save the weight values."
                        ],
                },
                {
                    startHidden: true,
                    sectionTitle: 'Editing Final Grades Data',
                    sectionVideo: 'Final-Grades movie - 3b.mp4',
                    sectionTextArray:
                        [
                            "To edit the reported grades, you first click on the 'show' button of that component on its tool panel but only\n" +
                            "if the component is hidden.\n" +
                            "Click on the checkbox next to the header of the column titled 'GR' which stands for 'Grades Reported'.\n" +
                            "This makes that column go into edit mode.\n" +
                            "Edit the values in the column in the usual way.\n" +
                            "There are no restrictions on the values of 'Grades Reported' so that a letter or number value may be entered.\n" +
                            "You can also leave a cell empty with no default implied.\n" +
                            "When there are changes in any reported value, the save button on that component is enabled.\n" +
                            "Click save to save the reported grades.\n" +
                            "\n"
                        ],
                }
            ],
        },

        StudentsPage: {
            mainTitleText: 'Students Page',

            parts: [
                {
                    startHidden: true,
                    sectionTitle: 'Overview of Students Page',
                    sectionImage: 'Students Page - fig 1.png',
                    sectionTextArray:
                        [
                            "This page can only be reached from the navigation bar's 'Students' link located on the navigation bar either\n" +
                            "by itself (on a desktop) or through the dropdown menu (on a mobile or small screen).\n",
                            "The students page shows the list of students that may be enrolled in any offering.  New students may be added to\n" +
                            "the list and may be deleted from the list.  A student deleted from the list will be automatically deleted from\n" +
                            "every offering in which that student is enrolled.  Students who are added will be added to the pool of students\n" +
                            "that can be enrolled in one or more offerings but are not automatically added to any offering.\n",
                            "Various ways are available to add students or import students, including reading a list of students from a comma\n" +
                            "separated file.  When adding students, only student IDs must be unique and multiple students with identical\n" +
                            "first name, last name and email are considered distinct if their student IDs are different.\n",
                            "The student ID, first name, last name and email of any student may be edited without affecting any of that student's\n" +
                            "data associated with any grade in any offering.\n"
                        ],
                },
                {
                    startHidden: true,
                    sectionTitle: 'Editing and deleting students',
                    sectionVideo: 'Students movie - 1b.mp4',
                    sectionTextArray:
                        [
                            "Click on the 'show' button of the section 'Students list' on the tool panel but only if that component is hidden.\n" +
                            "To edit a student's data, click on the checkbox next to the header of the data you want to change to go into edit mode\n" +
                            "for all the data in that column.\n" +
                            "Edit the data for that header for one or more students in the usual way.\n" +
                            "Click save on the tools panel of that component to save the edited data.\n" +
                            "To delete a student, click on 'edit list' on the tool panel of the assessment component.\n" +
                            "When 'edit list' is selected, 1 additional button shows on the 'Students list' tool panel which is the delete button.\n" +
                            "In addition, in the table, boxes appear in the first column.\n" +
                            "To select one or more students for deletion, click on the box in the first column of its row when 'edit list' is selected.\n" +
                            "The row will turn red.\n" +
                            "Clicking again consecutively on the same box will deselect it.\n" +
                            "Clicking on another box will select another student so that you can select multiple students to delete.\n" +
                            "Having selected students to be deleted with their rows shown in red, click the delete button.\n" +
                            "The students will be immediately deleted and removed from the table.\n" +
                            "You do not need to save, the new list of students is saved by simply clicking on the delete.\n" +
                            "Note that if deleted students were enrolled in any offering, they will be un-enrolled and all their data deleted."
                        ],
                },
                {
                    startHidden: true,
                    sectionTitle: 'Adding students by direct input',
                    sectionVideo: 'Students movie - 2b.mp4',
                    sectionTextArray:
                        [
                            "Click on the 'show' button of the section 'Student single input' on the tool panel but only if that component is hidden.\n" +
                            "Enter data in the fields: 'Code', 'First Name', 'Last Name' and 'Email'.\n" +
                            "Only 'Code' is a required field and there are no restrictions on the other fields.\n" +
                            "The 'Code' field must be one or more characters and must not be the same as a pre-existing student code.\n" +
                            "The 'save' button is disabled until the 'code' field is valid.\n" +
                            "Once done editing and if the code field is valid, the 'save' button is enabled.\n" +
                            "Click on 'save' in the components tool panel to add the student.\n" +
                            "The table in the 'Students list' is updated whether hidden or not."
                        ],
                },
                {
                    startHidden: true,
                    sectionTitle: 'Adding students from a CSV file',
                    sectionVideo: 'Students movie - 3b.mp4',
                    sectionTextArray:
                        [
                            "You must first prepare a Comma-Separated Values file.\n" +
                            "The file must have one or more lines with each line having up to 4 values separated by commas.\n" +
                            "A missing value is indicated by having commas without data.\n" +
                            "Now click on the 'Read CSV file' and select the 'csv' file that you've prepared.\n" +
                            "The data will be loaded in the table below.\n" +
                            "You can re-arrange columns by clicking on the titles of each column consecutively.\n" +
                            "Here, the 1st column is exchanged with the 3rd column ... and the 2nd column with the 4th.\n" +
                            "You may edit any field as needed.\n" +
                            "You first click on the edit box near the header of the column you want to edit to go into edit mode\n" +
                            "for all the data in that column.\n" +
                            "Edit the data for that header for one or more students in the usual way.\n" +
                            "The only constraint is that the 'Code' field must be one or more characters and none of the input must be the\n" +
                            "same as a pre-existing student code.\n" +
                            "Once done editing and if the code fields are all valid, the 'save' button is enabled.\n" +
                            "Click on 'save' in the components tool panel to add all the students.\n" +
                            "The students are cleared from the 'Read CSV file' component and added and saved in the 'Students list'.\n" +
                            "The table in the 'Students list' is updated and saved whether hidden or not."
                        ],
                }
            ],
        },

        ArchivingPage: {
            mainTitleText: 'Archiving and Backups Page',

            parts: [
                {
                    startHidden: true,
                    sectionTitle: 'Overview of Archiving Page',
                    sectionImage: 'Archiving Page - fig 1.png',
                    sectionTextArray:
                        [
                            "'Archiving and Backup' page can only be reached from the 'Courses' page by clicking on the 'Go to Archiving' button.  In the 'Archiving and Backup' page, you can download any offering(s) as an Excel-format file.  You can also download any offering(s) as a '.mygo' file from which you can restore later into your account or any other account.  After downloading a '.mygo' file, you can save it for later use, or you can send a copy to any other user who can then upload the file into their account. " +
                            "In addition to downloading files, you can alternatively create a snapshot of any number of offerings to be saved in the cloud which will be available to you on the 'Archiving and Backup' page.  These cloud offering snapshots can be deleted, can have reference notes attached and edited, and can be loaded back into your account at any time.  An offering snapshot works the same way as '.mygo' file but is only available to the user that created it. " +
                            "A '.mygo' backup files or an offering snapshot is a frozen state of an offering at the time the backup file or offering snapshot was created.  An offering that has been loaded from either of those is independent of the original offering from which it was created and distinct from any other existing offering.  An offering that is uploaded will create a new course only if it doesn't already exist.  If there is an existing course and offering that has the same course code, course number, offering date, offering period and offering section,then the section name of the offering being uploaded will have a '*' appended to its section name. When loaded back, an offering will be independent of the original one and will never overwrite the original offering if it exists and independent of any other existing offering. " +
                            "Finally, the 'Archiving and Backup' page allows the loading of one or more '.mygo' files.  Any number of '.mygo' files may be selected and loaded simultaneously as long as the number of courses and offerings do not exceed the user's quota.  Quotas may be expanded according to the subscription agreement. Such quotas also apply when loading offerings from offering snapshots."
                        ],
                },
                {
                    startHidden: true,
                    sectionTitle: 'Deleting Snapshots',
                    sectionVideo: 'Archiving movie - 1a.mp4',
                    sectionTextArray:
                        [
                            "Click on the 'show' button of the section 'Offering Snapshots' on the tool panel but only if that component is hidden.\n" +
                            "To delete an offering snapshot, you first select the offering snapshot for deletion by clicking on the box in the first column of the row containing the offering snapshot.\n" +
                            "The row turns red.\n" +
                            "You may select multiple offering snapshots to be deleted by clicking on multiple boxes.\n" +
                            "To de-select an offering snapshot from being deleted, click on the same box again and the red color will be removed from the row.\n" +
                            "To delete all selected offering snapshots, click on the delete button.\n" +
                            "A confirmation notice is shown.\n" +
                            "You may cancel the operation by clicking on the cancel button.\n" +
                            "To proceed, click on the 'delete' button.\n" +
                            "A success notice is shown.\n" +
                            "Click ok and the table is updated to show only the remaining offering snapshots."
                        ],
                },
                {
                    startHidden: true,
                    sectionTitle: 'Editing notes',
                    sectionVideo: 'Archiving movie - 2a.mp4',
                    sectionTextArray:
                        [
                            "Click on the 'show' button of the section 'Offering Snapshots' on the tool panel but only if that component is hidden.\n" +
                            "To edit a note associated with an offering snapshot, click on the checkbox next to the header of 'note' for that column to go into edit mode.\n" +
                            "You may edit one or more 'note' fields as needed.\n" +
                            "Click on the save button to save changes.\n"
                        ],
                },
                {
                    startHidden: true,
                    sectionTitle: 'Loading Offerings from Snapshots',
                    sectionVideo: 'Archiving movie - 3a.mp4',
                    sectionTextArray:
                        [
                            "Click on the 'show' button of the section 'Offering Snapshots' on the tool panel but only if that component is hidden.\n" +
                            "To select an offering snapshots for loading, you first select the offering snapshot by clicking on any content in 'Date', 'Course' or 'Offering' columns.\n" +
                            "All the text of those three columns are then highlighted.\n" +
                            "You may select multiple offering snapshots to be loaded by clicking on the content of multiple rows.\n" +
                            "To de-select an offering snapshot from being loaded, click on the same box again and the highlighting will be removed from the row.\n" +
                            "We have selected 3 offerings.\n" +
                            "One offering of a course EDUC232 not present in this account.\n" +
                            "An offering of a course ACCT210 that is present but the offering 2020 First Quarter Bee is not.\n" +
                            "And an offering of course ACCT210 and offering 2016 Fall See, both of which are already present in this account.\n" +
                            "To load all selected offering snapshots into offerings, click on the 'Load into offerings' button.\n" +
                            "A confirmation notice is shown.\n" +
                            "You may cancel the operation by clicking on the cancel button.\n" +
                            "To proceed, click on the 'confirm' button.\n" +
                            "After the operation is completed, a success notice is shown.\n" +
                            "Click ok to return to normal operations.\n" +
                            "The corresponding offerings have been created.\n" +
                            "A new course has been created with a new offering.\n" +
                            "The existing course has been augmented by two new offerings.\n" +
                            "The offering which has the same specification as a previous offering has the symbol star appended to the end of its section specification."
                        ],
                },
                {
                    startHidden: true,
                    sectionTitle: 'Downloading Excel or Backup files from Offerings',
                    sectionVideo: 'Archiving movie - 4a.mp4',
                    sectionTextArray:
                        [
                            "Click on the 'show' button of the section 'Offerings Operations' on the tool panel but only if that component is hidden.\n" +
                            "The table shows the courses and the number of offerings in each course.\n" +
                            "Click on the name of the course or the adjacent offering column to expand the column to show all the offerings for that course.\n" +
                            "Clicking again will collapse the row to its original state.\n" +
                            "Click on one or more offerings in the expanded column to select it.\n" +
                            "Click on the same offering to de-select it.\n" +
                            "An offering remains selected even if the row is collapsed.\n" +
                            "You may select multiple offerings from multiple courses.\n" +
                            "To download all selected offerings into Excel files, click the 'Download Excel' button.\n" +
                            "A confirmation notice is shown.\n" +
                            "You may cancel the operation by clicking on the cancel button.\n" +
                            "To proceed, click on the 'confirm' button.\n" +
                            "After the operation is completed, a success notice is shown.\n" +
                            "Click ok to return to normal operations.\n" +
                            "Notice that all the offerings remain selected.\n" +
                            "To download backup files you may select the currently selected offerings or a different set of offerings.\n" +
                            "To clear all selected offerings, click on the hide button then the show button afterwards.\n" +
                            "Clearing all selected offerings can be done at any time.\n" +
                            "To download all selected offerings into backup files, click the 'Download Backup' button.\n" +
                            "A confirmation notice is shown.\n" +
                            "You may cancel the operation by clicking on the cancel button.\n" +
                            "To proceed, click on the 'confirm' button.\n" +
                            "After the operation is completed, a success notice is shown.\n" +
                            "Click ok to return to normal operations."
                        ],
                },
                {
                    startHidden: true,
                    sectionTitle: 'Creating Snapshots of Offerings',
                    sectionVideo: 'Archiving movie - 5a.mp4',
                    sectionTextArray:
                        [
                            "Click on the 'show' button of the section 'Offerings Operations' on the tool panel but only if that component is hidden.\n" +
                            "The table shows the courses and the number of offerings in each course.\n" +
                            "Click on the name of the course or the adjacent offering column to expand the column to show all the offerings for that course.\n" +
                            "Clicking again will collapse the row to its original state.\n" +
                            "Click on one or more offerings in the expanded column to select it.\n" +
                            "Click on the same offering to de-select it.\n" +
                            "You may select multiple offerings from multiple courses.\n" +
                            "An offering remains selected even if the row is collapsed.\n" +
                            "To save all selected offerings into offering snapshots, click the 'Create Snapshot' button.\n" +
                            "A confirmation notice is shown.\n" +
                            "You may cancel the operation by clicking on the cancel button.\n" +
                            "To proceed, click on the 'confirm' button.\n" +
                            "After the operation is completed, a success notice is shown.\n" +
                            "Click ok to return to normal operations.\n" +
                            "Notice that all the offerings remain selected.\n" +
                            "Notice that the new offering snapshots appear in the 'Offering Snapshots' table."
                        ],
                },
                {
                    startHidden: true,
                    sectionTitle: 'Loading Offerings from Backups',
                    sectionVideo: 'Archiving movie - 6a.mp4',
                    sectionTextArray:
                        [
                            "Click on the 'Read My Grades Online archive files' button.\n" +
                            "A file browser appears.\n" +
                            "Select one or more '.mygo' files.\n" +
                            "Only '.mygo' files may be selected.\n" +
                            "Here we will select two offerings for which we have no course and two offerings for which we have an existing course.\n" +
                            "One of the offerings of the existing course already exists in our offerings while the other does not.\n" +
                            "Don't worry, the existing course will NOT be overwritten.\n" +
                            "Once you have finished selecting the files, click on 'Open' to proceed.\n" +
                            "After the operation is completed, a success notice is shown.\n" +
                            "Click ok to return to normal operations.\n" +
                            "Notice that the new offerings appear.\n" +
                            "The offerings from a new course will have their course created and the two offerings are added.\n" +
                            "Two additional offerings are added to the existing course.\n" +
                            "The pre-existing offering has a double where a '*' has been added to the section of the new one.\n"
                        ],
                },
            ],
        },

    }
}