<template>
  <div :class="['AppBackground--scoped']"
       :style="{'background-color': config[AppThemeSetting].appAllUnits.appBackgroundColor,
       'width': this.windowWidth + 'px'
        }">
  </div>
</template>

<script>
import config from "../../config/config-css.js"
import checkMobileMixin from '../../mixins/CheckMobileMixin_MX.js';

export default {
  data: () => {
    return {config: config}
  },
  props: {
    AppThemeSetting: {
      type: String,
      required: false,
      default: "themeOne"
    }
  },
  mixins: [checkMobileMixin],

}
</script>

<style scoped>
.AppBackground--scoped {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -100;
}

</style>