<template>
  <span>
    <AppButton
        v-show="!isHide"
        :clickFunction="doClickHide"
        :buttonId="'hideButton'"
        :buttonText="configCss.hideButtonTextHide"
        :buttonPadding="configCss.hideButtonPadding"
        :buttonEnabled="true"

        :buttonTextFontColor="configCss.textColorHide"
        :buttonTextFontSize="configCss.textFontSize"
        :buttonTextFontFamily="configCss.textFontFamily"
        :buttonBackgroundColor="configCss.backgroundColorHide"
        :buttonHoverBackgroundColor="configCss.backgroundColorHideHover"
        :buttonBorder="configCss.border"
        :buttonBorderRadius="configCss.borderRadius"
        :buttonBoxShadow="configCss.boxShadow"
        :buttonDisabledTextFontColor="configCss.disabledTextFontColor"
        :buttonDisabledBoxShadow="configCss.disabledBoxShadow"
    />
    <AppButton
        v-show="isHide"
        :clickFunction="doClickShow"
        :buttonId="'hideButton'"
        :buttonText="configCss.hideButtonTextShow"
        :buttonPadding="configCss.hideButtonPadding"
        :buttonEnabled="true"

        :buttonTextFontColor="configCss.textColorShow"
        :buttonTextFontSize="configCss.textFontSize"
        :buttonTextFontFamily="configCss.textFontFamily"
        :buttonBackgroundColor="configCss.backgroundColorShow"
        :buttonHoverBackgroundColor="configCss.backgroundColorShowHover"
        :buttonBorder="configCss.border"
        :buttonBorderRadius="configCss.borderRadius"
        :buttonBoxShadow="configCss.boxShadow"
        :buttonDisabledTextFontColor="configCss.disabledTextFontColor"
        :buttonDisabledBoxShadow="configCss.disabledBoxShadow"
    />
  </span>
</template>

<script>
import AppButton from "@/components/Buttons/AppButton";
import config from "../../config/config-css";

// emits 'clickSave' with argument buttonId
export default {
  data: () => {
    return {
      config,
    }
  },

  props: {
    AppThemeSetting: {
      type: String,
      required: false,
      default: "themeOne"
    },
    isHide: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  components: {AppButton},

  methods: {
    doClickHide() {
      this.$emit('clickHide')
    },
    doClickShow() {
      this.$emit('clickShow')
    },
  },

  computed: {
    configCss() {
      return this.config[this.AppThemeSetting].appAllUnits.hideButton
    }
  }

}
</script>