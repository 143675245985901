<template>
  <div>
    <AppNavbar
        :navbarFixedTop=navbarFixedTop
        :navbarHeight=configCss.navbarHeight
        :navbarLogo=configCss.navbarLogo
        :navbarLogoPadding="configCss.navbarLogoPadding"
        :navbarLogoHeight=configCss.navbarLogoHeight
        :dropdownIconName=configCss.navbarDropdownIconName
        :dropdownIconHeight=configCss.navbarDropdownIconHeight
        :dropdownButtonAllShiftFromRight="configCss.navbarDropdownButtonPaddingFromLeft"
        :dropdownButtonPadding=configCss.navbarDropdownButtonPadding
        :logoClicked=navbarLogoClicked

        :leftLinks=navbarLeftLinks
        :defaultDropdownLinks=navbarInitialDropDownLinks

        :dropdownButtonBackgroundColor=configCss.navbarDropdownButtonBackgroundColor
        :navbarBackgroundColor=configCss.navbarBackgroundColor
        :navbarBorderBottom=configCss.navbarBorderBottom
        :leftLinksFontFamily=configCss.navbarFontFamilyLeftLinks
        :leftLinksFontSize=configCss.navbarLeftLinksFontSize
        :leftLinksFontColor=configCss.navbarLeftLinksFontColor
        :leftLinksHoverColor=configCss.navbarLeftLinksHoverColor
        :dropdownContentHeight=configCss.navbarDropdownContentHeight
        :dropdownWidth=configCss.navbarDropdownWidth
        :dropdownContentHeightCollapse=configCss.navbarDropdownContentHeightCollapse
        :dropdownContentFontSize=configCss.navbarDropdownContentFontSize
        :dropdownContentFontFamily=configCss.navbarFontFamilyDropdownLinks
        :dropdownContentFontColor=configCss.navbarDropdownContentFontColor
        :dropdownContentHoverColor=configCss.navbarDropdownContentHoverColor
        :dropdownContentBackgroundColor=configCss.navbarDropdownContentBackgroundColor
        :dropdownContentPadding=configCss.navbarDropdownContentPadding
        :dropdownContainerPadding=configCss.navbarDropdownContainerPadding
        :dropdownContainerBorder=configCss.navbarDropdownContainerBorder
        :dropdownContentShiftDown=configCss.navbarDropdownContentShiftDown
        :dropdownContentShiftRight=configCss.navbarDropdownContentShiftRight
    />

    <AppModalOK
        :AppThemeSetting="AppThemeSetting"
        :showModal="showOKModal"
        :titleText="'Just OK'"
        :bodyText="'Placeholder for just OK.'"
        :buttonText="'ok'"
        :buttonPadding="'2px 16px 2px 16px'"
        @clickOK="doClickOkModal"
    />
  </div>
</template>


<script>
import AppNavbar from "@/components/navbar-toolpanel/AppNavbar";
import AppModalOK from "@/components/Modals/AppModalOK";
import config from "../../config/config-css.js"
import router from "@/router";

// const environment = process.env.VUE_APP_ENVIRONMENT || "testing";


export default {
  data: () => {
    return {
      config,
      showOKModal: false,
      navbarDefaultDropdownLinks: undefined,
      navbarLogoClicked: () => {

        if (router.currentRoute.name !== 'Home') {
          router.push({name: 'Home'});
        }
        window.scrollTo(0, 0);
      },
      navbarFixedTop: true,
    }
  },

  components: {
    AppNavbar, AppModalOK
  },

  props: {
    AppThemeSetting: {
      type: String,
      required: false,
      default: "themeOne"
    },
    additionalNavbarDropdownLinks: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    }
  },

  methods: {
    async doClickOkModal() {
      this.showOKModal = false;
    },

    initialDropdown() {
      const theLinks = [];
      for (let item of this.configCssPL.items) {
        theLinks.push(
            {
              clickFunction: () => {
                if (router.currentRoute.name === item.name) {
                  window.scrollTo(0, 0)
                } else {
                  router.push({
                    name: item.name,
                    params: {
                      AppThemeSetting: this.AppThemeSetting
                    }
                  });
                  window.scrollTo(0, 0)
                }
              },
              key: item.name, label: item.label, type: "regular"
            }
        )
      }
      this.navbarDefaultDropdownLinks = theLinks
    }
  },

  computed: {
    navbarLeftLinks() {
      return [
        {
          clickFunction: () => {
            if (router.currentRoute.name === "Home") {
              window.scrollTo(0, 0)
            } else {
              router.push({
                name: 'Home',
                params: {
                  AppThemeSetting: this.AppThemeSetting
                }
              });
              window.scrollTo(0, 0)
            }
          },
          key: 'Home', label: 'Home', type: "regular"
        },
        {
          clickFunction: () => {
            router.push({
              name: 'ContactUs',
              params: {
                AppThemeSetting: this.AppThemeSetting
              }
            })
          }, key: 'ContactUs', label: 'Contact Us', type: "regular"
        }
      ]
    },
    configCss() {
      return config[this.AppThemeSetting].theNavbar
    },
    configCssPL() {
      return this.config[this.AppThemeSetting].ContentPageLinks
    },

    navbarInitialDropDownLinks() {
      return [...this.navbarDefaultDropdownLinks, ...this.additionalNavbarDropdownLinks]
    }
  },

  created() {
    this.initialDropdown()
  }
}
</script>

<style scoped>
</style>