export default {
    contactUsEXT
}

// Bring helper
import mgoAPIAxiosRequestHelper from "@/api/mgo-api-axiosRequestHelper";

const {
    mgoAPIAxiosRequestPreLogin
} = {...mgoAPIAxiosRequestHelper};

async function contactUsEXT({email, message, isLoggedIn}) {
    const {success, status} = await mgoAPIAxiosRequestPreLogin({
        path: `/identifyapi/contactus/add`, method: 'post',
        withCredentials: true, body: {email, message, isLoggedIn}
    });
    return {success, status};
}
